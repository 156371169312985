import { useLocalStorage } from "../utils/LocalStorage";
import { uniq } from "lodash";
import { Upload } from "../models/Upload";
import { useProjects } from "./useProjects";
import { useUploads } from "./useUploads";
import { Project } from "../models/Project";


export type ProjectTableModel = {
    id: string;
    project: Project;
    upload: Upload | undefined;
    active: boolean;
    created: Date;
};

function sortProjectsList(data: ProjectTableModel[], starMap?: { [x: string]: boolean; } | undefined) {
    const result = [...data];
    result.sort((a, b) => {
        return new Date(b.created).getTime() - new Date(a.created).getTime();
    });

    result.sort((a, b) => {
        const aStar = starMap ? starMap[a.id] : false;
        const bStar = starMap ? starMap[b.id] : false;

        if (aStar === bStar)
            return 0;

        if (aStar)
            return -1;

        return 1;
    });

    return result;
}

export function useProjectsList(): [ProjectTableModel[] | undefined, boolean] {
    const [projects = [], isLoadingProjects] = useProjects();
    const uploadIds = uniq(projects.map(project => project.uploadId).filter(uploadId => uploadId !== undefined) as string[]);
    const [starMap] = useLocalStorage<boolean>("projectbrowser");
    
    const [uploads = [], isLoadingUploads] = useUploads({ idEq: uploadIds }, {disable: projects.length === 0});
    const isLoading = isLoadingProjects || isLoadingUploads;

    const newTable = projects.map(project => {
        return {
            id: project.id,
            project: project,
            upload: uploads.find((item: Upload) => (item.id === project.uploadId && item)),
            created: project.created,
        } as ProjectTableModel;
    });

    return [sortProjectsList(newTable, starMap), isLoading];
}
