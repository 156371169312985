import { range } from "lodash";
import React, { useState } from "react";
import i18n from "../../i18n";
import { Day } from "./Day";
import { classNames } from "../../utils/Utils";

enum CalendarModes {
    Month,
    Year,
}

type CalendarStateType = {
    decade: number;
    mode: CalendarModes;
    year: number;
    month: number;
    selectedDay?: number;
    selectedMonth?: number;
    selectedYear?: number;
}

export type CalendarPropsType = {
    year: number;
    month: number;
    selectedDay?: number;

    /**
     * This handler is invoked for each day rendered in the calendar. It can
     * add custom CSS classes to a cell by returning it's name.
     */
    dayCellClass?: (day: Day) => string | undefined;
    onClick?: (day: { year: number, month: number, day: number }) => void;
}

export function Calendar(props: CalendarPropsType) {
    const [state, setState] = useState<CalendarStateType>({
        decade: Math.floor(props.year / 10) * 10,
        mode: CalendarModes.Month,
        month: props.month,
        year: props.year,
        selectedMonth: props.month,
        selectedYear: props.year,
        selectedDay: props.selectedDay
    });

    const months = [
        i18n.t("datetime.months.january"),
        i18n.t("datetime.months.february"),
        i18n.t("datetime.months.march"),
        i18n.t("datetime.months.april"),
        i18n.t("datetime.months.may"),
        i18n.t("datetime.months.june"),
        i18n.t("datetime.months.july"),
        i18n.t("datetime.months.august"),
        i18n.t("datetime.months.september"),
        i18n.t("datetime.months.october"),
        i18n.t("datetime.months.november"),
        i18n.t("datetime.months.december")
    ];

    return <>
        {state.mode === CalendarModes.Month && <div className="calendarPlaceholder">
            <div className="calendarHeaderBar" onWheel={wheelHandler}>
                <div onClick={() => { addMonths(-1); }}>&lt;</div>
                <div onClick={() => { setMode(CalendarModes.Year); }} >{months[state.month - 1]} {state.year}</div>
                <div onClick={() => { addMonths(1); }}>&gt;</div>
            </div>
            <div className="calendar" onWheel={wheelHandler}>
                <div className="dayHeader">{i18n.t("datetime.weekdaysShort.monday")}</div>
                <div className="dayHeader">{i18n.t("datetime.weekdaysShort.tuesday")}</div>
                <div className="dayHeader">{i18n.t("datetime.weekdaysShort.wednesday")}</div>
                <div className="dayHeader">{i18n.t("datetime.weekdaysShort.thursday")}</div>
                <div className="dayHeader">{i18n.t("datetime.weekdaysShort.friday")}</div>
                <div className="dayHeader">{i18n.t("datetime.weekdaysShort.saturday")}</div>
                <div className="dayHeader">{i18n.t("datetime.weekdaysShort.sunday")}</div>
                {getCells(state.year, state.month)}
            </div>
        </div>}
        {state.mode === CalendarModes.Year && <div className="light calendarPlaceholder">
            <div className="calendarYears">
                <button className="center" onClick={() => { handleDecadeDelta(-10); }}>&lt;</button>
                <div className="years" onWheel={ handleDecadeWheel }>
                    {range(state.decade, state.decade + 10).map(y => <div key={y} className={classNames(["year", state.selectedYear === y && "selected"])} onClick={() => { setYear(y); }}>{y}</div>)}
                </div>
                <button className="center" onClick={() => { handleDecadeDelta(10); }}>&gt;</button>
            </div>
        </div>}
    </>;

    function handleDecadeWheel(e: React.WheelEvent<HTMLDivElement>) {
        handleDecadeDelta(10 * Math.sign(e.deltaY));
    }

    function handleDecadeDelta(delta: number) {
        setState({ ...state, decade: state.decade + delta});
    }

    function setMode(mode: CalendarModes) {
        setState({ ...state, mode });
    }

    function setYear(year: number) {
        setState({ ...state, mode: CalendarModes.Month, year });
    }

    function wheelHandler(event: React.WheelEvent<HTMLDivElement>) {
        addMonths(Math.sign(event.deltaY));
    }

    function getCells(year: number, month: number) {
        const result: JSX.Element[] = [];
        const day = new Day(year, month, 1);

        // Weekday indexes returned by getDay start with Sunday (=0), and
        // end with Saturday (=6). However, I want my weeks to start with
        // monday, so I', giving sundays an index of 7 here:
        const firstDayOfWeek = day.toDate().getDay() || 7;
        const daysInMonth = Day.daysInMonth(year, month);

        let cellsRendered = 0;
        for (let i = 1; i < firstDayOfWeek; i++, cellsRendered++) {
            result.push(<div key={"pad-" + i} className="cell padding"></div>);
        }

        for (let i = 1; i <= daysInMonth; i++, cellsRendered++) {
            const isDaySelected = state.selectedDay === i && state.selectedMonth === state.month && state.selectedYear === state.year;
            const className = classNames(["cell", "cellInteractive", props.dayCellClass && props.dayCellClass(day), isDaySelected && "daySelected"]);

            result.push(<div key={"day-" + i} onClick={() => {
                if (props.onClick)
                    props.onClick({
                        year: year,
                        month: month,
                        day: i
                    });

                setState({
                    ...state,
                    selectedMonth: month,
                    selectedDay: i,
                    selectedYear: year
                });
            }} className={className}> {i}</div >);
            day.nextDay();
        }

        for (; cellsRendered < 7 * 6; cellsRendered++)
            result.push(<div key={"pad-" + cellsRendered} className="cell padding">&nbsp;</div>);

        return result;
    }

    function addMonths(offset: number) {
        let month = state.month;
        let year = state.year;

        month += offset;
        while (month < 1) {
            month += 12;
            year--;
        }

        while (month > 12) {
            month -= 12;
            year++;
        }

        setState({ ...state, month, year });
    }
}