import React, { useCallback, useEffect, useRef, useState } from "react";

export type Bar = {
    value: number;
    displayValue: string;
    label: string;
}

export function HorizontalBars(props: {
    data: Bar[],
    onSelected?: (value?: string, sumValueFormatted?: string) => void;
    title?: string;
    selectedIndex?: number | number[];
    legendLabel?: string;
    verticalLinesCount?: number;
    maxValue: number;
}) {
    const [verticalLinesWidth, setVerticalLinesWidth] = useState(0);

    const additionalStyles = props.onSelected !== undefined ? {
        cursor: "pointer"
    } : undefined;

    const verticalLinesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setVerticalLinesWidth(verticalLinesRef.current?.clientWidth ?? 0);
    });

    // Are we closer to 100, 200, etc...
    // We need to always make sure that this is not zero to avoid infinity loops.
    const nearestRoundup = Math.max(1, Math.ceil(props.maxValue / 100)) * 100;

    // The bar width should be a represenation of the full chart's width
    const calculateBarWidth = useCallback((value: number) => {
        const barWidth = Math.floor((value * verticalLinesWidth) / nearestRoundup);
        return barWidth;
    }, [verticalLinesWidth, props.maxValue]);

    return <>
        <div
            onClick={() => {
                if (props.onSelected)
                    props.onSelected(undefined);
            }}
            className="horizontalBarsContainer">
            <p className="title">{props.title}</p>

            <div className="verticalLinesAndBars">

                <div className="bars">
                    {props.data.map((reason: any, index: number) => <div key={index} className="horizontalBarContainer">
                        <div className="barContainer">
                            <span className="top-tick"></span>
                            <div className="labelContainer" >
                                <p style={{ ...additionalStyles }} onClick={(e) => {
                                    if (props.onSelected)
                                        if (index === props.selectedIndex)
                                            props.onSelected(undefined);
                                        else
                                            props.onSelected(reason.label, reason.displayValue);
                                    e.stopPropagation();
                                }} className="label" title={reason.label}>{reason.label}</p>
                                {index + 1 === props.data.length && <span className="bottom-tick"></span>}
                            </div>
                            <div
                                className={`bar ${props.selectedIndex === index ? "selected" : undefined}`}
                                onClick={(e) => {
                                    if (props.onSelected)
                                        if (index === props.selectedIndex)
                                            props.onSelected(undefined);
                                        else
                                            props.onSelected(reason.label, reason.displayValue);

                                    e.stopPropagation();
                                }}
                                style={{ ...additionalStyles, width: `${calculateBarWidth(reason.value)}px` }}>
                                {calculateBarWidth(reason.value) > 100 && <span className="barValue">{reason.displayValue}</span>}
                            </div>
                            {calculateBarWidth(reason.value) <= 100 && <span className="barValue barValue--outside">{reason.displayValue}</span>}
                        </div>
                    </div>)}
                </div>

                <div className="verticalLines" ref={verticalLinesRef}>
                    {[...Array(props.verticalLinesCount).keys()].map((value: number) => <div key={value} className="verticalLine">
                        <span className="line"></span>
                    </div>)}
                </div>
            </div>
        </div>
    </>;
}
