import React, { useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useCaseGantt } from "../../hooks/UseCaseGantt";
import i18n from "../../i18n";
import { Formatter } from "../../utils/Formatter";
import { StatsSection, StatsSubSection } from "../stats-section/StatsSection";
import { StatsRowValue } from "../stats-section/StatsRowValue";
import { StatsRowString } from "../stats-section/StatsRowString";

export default function SideStatisticsCaseGantt() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const [caseGantt] = useCaseGantt();

    const selectedCase = caseGantt?.cases.find((c) => settings.selection.case === c.caseId);

    const title = `${i18n.t("common.caseId")}: ${selectedCase?.caseId}`;


    return <>
        {selectedCase !== undefined && <StatsSection className="breakWord" title={<div title={title}>{title}</div>}>
            <StatsSubSection>
                <StatsRowString label="common.throughputTime" value={Formatter.formatDurationShort(selectedCase.caseDuration, undefined, session.numberFormatLocale)} />

                {selectedCase.caseYieldCount !== undefined &&
                    <StatsRowValue label={`${i18n.t("common.yield")} (${i18n.t("quantities.count")})`} value={selectedCase.caseYieldCount} unit={Formatter.units.number} />}

                {selectedCase.caseYieldRateCount !== undefined &&
                    <StatsRowValue label={`${i18n.t("output.rate")} (${i18n.t("quantities.count")})`} value={selectedCase.caseYieldRateCount} unit={Formatter.units.countFlow} />}

                {selectedCase.caseYieldLength !== undefined &&
                    <StatsRowValue label={`${i18n.t("common.yield")} (${i18n.t("quantities.length")})`} value={selectedCase.caseYieldLength} unit={Formatter.units.metricLength} />}

                {selectedCase.caseYieldRateLength !== undefined &&
                    <StatsRowValue label={`${i18n.t("output.rate")} (${i18n.t("quantities.length")})`} value={selectedCase.caseYieldRateLength} unit={Formatter.units.speed} />}

                {selectedCase.caseYieldMass !== undefined &&
                    <StatsRowValue label={`${i18n.t("common.yield")} (${i18n.t("quantities.mass")})`} value={selectedCase.caseYieldMass} unit={Formatter.units.weight} />}

                {selectedCase.caseYieldRateMass !== undefined &&
                    <StatsRowValue label={`${i18n.t("output.rate")} (${i18n.t("quantities.mass")})`} value={selectedCase.caseYieldRateMass} unit={Formatter.units.massFlow} />}
            </StatsSubSection>
        </StatsSection>}
    </>;
}
