import { DateTime } from "luxon";
import { TimePeriodFrequencies } from "../models/ApiTypes";

export class Timestamp {
    /**
     * Creates a new Timestamp object.
     * @param year Year
     * @param month Month, 1-indexed
     * @param day Day,1-indexed
     * @param hour Hour, 0-indexed, 24-hour format
     * @param minute Minute, 0-indexed
     * @param second Second, 0-indexed
     * @param millisecond Millisecond, 0-indexed
     */
    constructor(public year: number, public month: number, public day: number, public hour: number, public minute: number, public second: number, public millisecond: number) { }

    toString() {
        return `${Timestamp.pad(this.year, 4)}-${Timestamp.pad(this.month, 2)}-${Timestamp.pad(this.day, 2)} ${Timestamp.pad(this.hour, 2)}:${Timestamp.pad(this.minute, 2)}:${Timestamp.pad(this.second, 2)}.${Timestamp.pad(this.millisecond, 2)}`;
    }

    private static pad(val: number | string, size: number) {
        let num = val.toString();
        while (num.length < size)
            num = "0" + num;

        return num;
    }

    public static clone(timestamp: Timestamp) {
        return new Timestamp(timestamp.year, timestamp.month, timestamp.day, timestamp.hour, timestamp.minute, timestamp.second, timestamp.millisecond);
    }
}

export function addStep(timestamp: Timestamp, timezone: string, size: TimePeriodFrequencies, excludeEndDay = false, stepSize = 1) {
    function lxToTimestamp(lx: DateTime) {
        return new Timestamp(lx.year, lx.month, lx.day, lx.hour, lx.minute, lx.second, lx.millisecond);
    }

    const ts = DateTime.fromObject({
        ...timestamp,
    }, {
        zone: timezone
    });

    const endDayExclusion = excludeEndDay ? 1 : 0;

    switch (size) {
        case TimePeriodFrequencies.Hour:
            return lxToTimestamp(ts.plus({ hours: stepSize }).minus({ days: endDayExclusion }));
        case TimePeriodFrequencies.Day:
            return lxToTimestamp(ts.plus({ days: stepSize }).minus({ days: endDayExclusion }));
        case TimePeriodFrequencies.Month:
            return lxToTimestamp(ts.plus({ months: stepSize }).minus({ days: endDayExclusion }));
        case TimePeriodFrequencies.Week:
            return lxToTimestamp(ts.plus({ weeks: stepSize }).minus({ days: endDayExclusion }));
        case TimePeriodFrequencies.Year:
            return lxToTimestamp(ts.plus({ years: stepSize }).minus({ days: endDayExclusion }));
    }
}

/**
 * This is a comparison function that can be used to sort timestamps.
 * @param a The first timestamp to compare.
 * @param b The second timestamp to compare.
 * @returns -1 if a < b, 1 if a > b, 0 if a = b.
 */
export function timestampSort(a: Timestamp | undefined, b: Timestamp | undefined) {
    if (a === undefined && b === undefined)
        return 0;
    if (a === undefined)
        return -1;
    if (b === undefined)
        return 1;
    
    if (a.year !== b.year)
        return a.year - b.year;
    if (a.month !== b.month)
        return a.month - b.month;
    if (a.day !== b.day)
        return a.day - b.day;
    if (a.hour !== b.hour)
        return a.hour - b.hour;
    if (a.minute !== b.minute)
        return a.minute - b.minute;
    if (a.second !== b.second)
        return a.second - b.second;
    if (a.millisecond !== b.millisecond)
        return a.millisecond - b.millisecond;

    return 0;
}

export function isInRange(from: Timestamp, to: Timestamp, value: Timestamp) {
    return timestampSort(from, value) < 1 &&
    timestampSort(value, to) < 1;
}

export function toUtcFromMillis(timestamp: number) {
    const dt = DateTime.fromMillis(timestamp, {
        zone: "UTC"
    });
    return new Timestamp(dt.year, dt.month, dt.day, dt.hour, dt.minute, dt.second, dt.millisecond);
}

export function toUtc(timestamp: Timestamp, timezone: string) {
    const dt = DateTime.fromObject(timestamp, {
        zone: timezone,
    }).toUTC();
    return new Timestamp(dt.year, dt.month, dt.day, dt.hour, dt.minute, dt.second, dt.millisecond);

}

export function utcToUserTimezone(timestamp: Timestamp, timezone: string) {
    const dt = DateTime.fromObject(timestamp, { zone: "utc" }).setZone(timezone);
    return new Timestamp(dt.year, dt.month, dt.day, dt.hour, dt.minute, dt.second, dt.millisecond);
}

export function toUserTimezoneMillis(timestamp: number, timezone: string) {
    const dt = DateTime.fromMillis(timestamp).setZone(timezone);
    return new Timestamp(dt.year, dt.month, dt.day, dt.hour, dt.minute, dt.second, dt.millisecond);
}

export function toUserTimezone(timestamp: string, timezone: string) {
    const dt = DateTime.fromISO(timestamp).setZone(timezone);
    return new Timestamp(dt.year, dt.month, dt.day, dt.hour, dt.minute, dt.second, dt.millisecond);
}

export function toUtcJs(timestamp: Timestamp, timezone: string) {
    return DateTime.fromObject({
        ...timestamp,
    }, {
        zone: timezone,
    }).setZone("UTC").toJSDate();
}

export function floorTime(time: number, interval: TimePeriodFrequencies, timezone: string) {
    const zoned = DateTime.fromMillis(time).setZone(timezone);
    const dt = zoned.startOf(interval);
    return new Timestamp(dt.year, dt.month, dt.day, dt.hour, dt.minute, dt.second, dt.millisecond);
}

/**
 * Returns the number of the week of the timestamp provided
 * @param timestamp Start of the week to return
 */
export function getWeekNumber(timestamp: Timestamp, timezone: string) {
    const dt = DateTime.fromObject({
        year: timestamp.year,
        month: timestamp.month,
        day: timestamp.day,
        hour: timestamp.hour,
        minute: timestamp.minute,
        second: timestamp.second,
        millisecond: timestamp.millisecond,
    }, {
        zone: timezone
    });
    return dt.weekNumber;
}

export function getMinDate(a: string | undefined, b: string | undefined) {
    if (a === undefined && b === undefined)
        return undefined;
    if (a === undefined)
        return b;
    if (b === undefined)
        return a;

    const minDate = new Date(Math.min(new Date(a).getTime(), new Date(b).getTime()));
    return minDate.toISOString();
}

export function getWeekDay(timestamp: Timestamp, timezone: string) {
    const dt = DateTime.fromObject({
        year: timestamp.year,
        month: timestamp.month,
        day: timestamp.day,
        hour: timestamp.hour,
        minute: timestamp.minute,
        second: timestamp.second,
        millisecond: timestamp.millisecond,
    }, {
        zone: timezone
    });
    
    return dt.weekday;
}