import { useContext } from "react";
import { Project } from "../models/Project";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";
import { SessionContext } from "../contexts/SessionContext";
import { GetProjectsRequest } from "../models/ApiTypes";

export function useProjects(request: Partial<GetProjectsRequest> = {}, options?: ApiHookOptions<Project[]>) {
    const session = useContext(SessionContext);

    const setDisabled =
        options?.disable || session.user === undefined;

    return useApi(
        Datastores.getProjects,
        request,
        [JSON.stringify(options)],
        { ...options, disable: setDisabled });
}