import { PerTimeperiodEquipmentStatisticsParams, TimePeriodFrequencies, disableAllCalcOptions } from "../models/ApiTypes";
import { SessionContext } from "../contexts/SessionContext";
import { SettingsContext } from "../contexts/SettingsContext";
import { useContext, useMemo } from "react";
import { EventKeys } from "../models/EventKeys";
import { useApi } from "./UseApi";
import { Datastores } from "../utils/Datastores";
import { defaultApiLimit } from "../Global";
import { AggregatedTimeperiodElementSchema, AggregatedTimeperiodSchema } from "./UseTimeAggregatedCaseStatistics";
import { PerTimePeriodEquipmentStatisticsResponse, TimeperiodEquipmentStatisticsSchema } from "../models/generated";

export function useEquipmentAggregationTimeperiods(request?: Partial<PerTimeperiodEquipmentStatisticsParams>, options?: {
    disable?: boolean,
}): [PerTimePeriodEquipmentStatisticsResponse | undefined, boolean, string | undefined] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    if (request?.equipment === undefined)
        throw new Error("request.equipment must be defined");

    const requestOptions: PerTimeperiodEquipmentStatisticsParams = {
        ...disableAllCalcOptions,
        ...request,
        equipment: request.equipment ?? [],
        eventFilters: request?.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: request?.eventKeys ?? session.project?.eventKeys ?? {} as EventKeys,
        frequency: request?.frequency ?? TimePeriodFrequencies.Day,
        uploadId: request?.uploadId ?? session.project?.uploadId ?? "",
        uploads: request?.uploads ?? session.project?.uploads,
        tz: request?.tz ?? session.timezone,
        limit: request?.limit ?? defaultApiLimit,
    };

    return useApi(
        Datastores.getEquipmentAggregationTimeperiods,
        requestOptions,
        [
            JSON.stringify(requestOptions)
        ], {
            ...options,
            disable: options?.disable || 
                session.project === undefined,
        });
}

/**
 * This hook returns also the equipment stats as the one above but it is formatted to adapt it to the schema used in the dashboard.
 * So we are using this hook only in the dashboard
 */
export function useEquipmentAggregationStatistics(request?: Partial<PerTimeperiodEquipmentStatisticsParams>, options?: {
    disable?: boolean,
}): [AggregatedTimeperiodSchema | undefined, boolean, string | undefined] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const requestOptions: PerTimeperiodEquipmentStatisticsParams = {
        ...disableAllCalcOptions,
        ...request,
        equipment: request?.equipment,
        eventFilters: request?.eventFilters ?? settings.previewFilters ?? settings.filters,
        eventKeys: request?.eventKeys ?? session.project?.eventKeys ?? {} as EventKeys,
        frequency: request?.frequency ?? TimePeriodFrequencies.Day,
        uploadId: request?.uploadId ?? session.project?.uploadId ?? "",
        uploads: request?.uploads ?? session.project?.uploads,
        tz: request?.tz ?? session.timezone,
        limit: request?.limit ?? defaultApiLimit,
    };

    const [data, isLoading, hash] = useApi(
        Datastores.getEquipmentAggregationTimeperiods,
        requestOptions,
        [
            JSON.stringify(requestOptions)
        ], {
            ...options,
            disable: options?.disable || 
                session.project === undefined,
        });

    const result = useMemo(() => {
        return data ? toAggregatedTimeperiodSchema(data) : undefined;
    }, [ data ]);
    
    return [result, isLoading, hash];
}

function toAggregatedTimeperiodSchema(data: PerTimePeriodEquipmentStatisticsResponse): AggregatedTimeperiodSchema {

    const actualMap = new Map<string, TimeperiodEquipmentStatisticsSchema>(data.timeperiods?.map((t) => [t.timeperiodStartTime, t]));

    const timestamps = Array.from(actualMap.keys());

    return {
        frequency: data.frequency,
        timeperiods: timestamps.map((timestamp) => {
            return {
                timeperiodStartTime: timestamp,
                actual: actualMap.get(timestamp),
            } as AggregatedTimeperiodElementSchema;
        }),
        log: {
            actual: undefined,
        },
    };
}