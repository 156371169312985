import React, { useContext } from "react";
import { ProductIdentifier, SettingsContext } from "../../contexts/SettingsContext";
import { KpiTypes } from "../../models/KpiTypes";
import { BaseMatrixProps } from "./MatrixCell";

export type WorkplaceMatrixCellProps = BaseMatrixProps & {
    workplace: ProductIdentifier;
    kpi: KpiTypes;
}

export default function WorkplaceMatrixCell(props: WorkplaceMatrixCellProps) {
    const settings = useContext(SettingsContext);
    const cellSelected = settings.selection.matrixElement as WorkplaceMatrixCellProps;

    const isSelected = settings.selection.matrixElement?.value !== undefined &&
        (cellSelected?.workplace?.name === props.workplace?.name) &&
        (cellSelected?.kpi === props.kpi);

    return (
        <div
            style={{
                top: `calc(var(--matrix-cell-box-height) * ${props.y})`,
                left: `calc(var(--matrix-cell-box-width) * ${props.x})`,
                backgroundColor: props.color,
            }}
            className={`matrixCell matrixCell--variant-${props.variant}${props.value !== undefined ? " clickable" : ""}${isSelected ? " matrixCell--selected" : ""}`}
            onClick={(e) => {
                e.stopPropagation();

                if (props.value === undefined) {
                    // Blank cell
                    settings.setSelection({});
                    return;
                }

                if (props.kpi !== undefined) {
                    settings.mergeSet({
                        kpiMatrix: {
                            machineName: props.workplace.name,
                        },
                        kpi: {
                            selectedKpi: props.kpi
                        }
                    });
                }

                if (!props ||
                    (settings.selection.matrixElement?.value === props.value) ||
                    (props.workplace === undefined || props.kpi === undefined)) {
                    // Blur selection
                    settings.setSelection({});
                    return;
                }

                settings.setSelection({
                    matrixElement: props
                });
            }}>
            {props.title && <div className="matrixCell__title">{props.title}</div>}
            {props.info && <div className="matrixCell__info">{props.info}</div>}
        </div>
    );
}
