import React from "react";
import { StatsSection, StatsSubSection } from "../../components/stats-section/StatsSection";
import { StatsRowString } from "../../components/stats-section/StatsRowString";
import i18n from "../../i18n";

type LossReasonsStatisticsProps = {
    machineName: string;
    reason?: string;
    sum?: string;
}

export function LossReasonsStatistics({ reason, sum }: LossReasonsStatisticsProps) {
    return <StatsSection title={reason ?? `${i18n.t("common.allReasons")}`}>

        {<StatsSubSection>
            <StatsRowString isHighlight={true} label="common.statistics.sum" value={`${sum}`} />
        </StatsSubSection>}

        {/* Localization stuff */}
        {/* <StatsSubSection title="common.localization" spotlightId="Localization">
            <StatsRowString label="common.machine" value={activityValues?.machine?.value ?? activityValues?.machine?.nUnique.toString() } />
            <StatsRowString label="common.machineType" value={activityValues?.machineType?.value ?? activityValues?.machineType?.nUnique.toString() } />
            <StatsRowString label="common.location" value={activityValues?.location?.value ?? activityValues?.location?.nUnique.toString() } />
        </StatsSubSection> */}
    </StatsSection>;
}


