import { isBoolean, isString } from "lodash";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import i18n from "../../i18n";
import { classNames } from "../../utils/Utils";

export type NavItemProps = {
    isVisible?: boolean;
    title: string;
    icon?: string;
    level?: number;
    targetUrl?: string | (() => string);
    isRouteActive?: boolean | ((url: string) => boolean);
    className?: string;
    id?: string;
    onClick?: () => void;
    idSlug?: string;
};

export function NavItem(props: NavItemProps) {
    const location = useLocation();

    if (props.isVisible === false)
        return <></>;

    const url = isString(props.targetUrl) ? props.targetUrl :
        props?.targetUrl?.();

    const isActive = isRouteActive(location.pathname, url, props);

    const level = props.level ?? 1;
    const title = i18n.t(props.title);

    return <NavLink
        className="navItem"
        to={{ pathname: url }}>
        <div
            title={title}
            id={props.id}
            onClick={props.onClick}
            className={"navItemLevel" + level.toString() + (isActive ? " selected" : "") + (props.className !== undefined ? " " + props.className : "")} >
            {props.icon !== undefined && <div className="center navIconContainer"><svg className={classNames(["svg-icon xsmall", `svg-${props.idSlug}`])}><use href={props.icon} /></svg></div>}
            <span className="oneRow">{title}</span>
        </div>
    </NavLink>;
}

export function isRouteActive(location: string, url: string | undefined, props: NavItemProps) {
    if (url === undefined)
        return false;

    if (props.isRouteActive === undefined)
        return location.endsWith(url);

    if (isBoolean(props.isRouteActive))
        return props.isRouteActive!;

    return (props.isRouteActive as (url: string) => boolean)(location);
}