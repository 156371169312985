import { get, uniq, uniqBy } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { QuantityDropdown } from "../../components/controls/KpiControlPrimitives";
import Dropdown, { StringOption } from "../../components/dropdown/Dropdown";
import Modal, { ModalProps } from "../../components/modal/Modal";
import { AggregationTypes } from "../../contexts/ContextTypes";
import { SessionContext, SessionType, isOniqDev, isOniqEmployee } from "../../contexts/SessionContext";
import { DashboadTileSettings, SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { caseKpiControlsGetAllowedStatistics, getKpiDefinition, TimeperiodApis } from "../../models/Kpi";
import { KpiPresets, KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { Quantity, quantities } from "../../utils/Quantities";
import { BaseQuantityType } from "../../models/ApiTypes";
import { Dimensions, hasCarbonDimension } from "../../components/dimension/Dimension";
import { useGraph } from "../../hooks/UseGraph";
import { Graph, GroupingKeys, NodeActivitySchema, NodeRoles } from "../../models/Dfg";
import { FormDeleteButton } from "../../components/forms/FormDeleteButton";

/**
 * This is strongly inspired by <KpiControls>, but that works on the settings context,
 * while we need to generate a tile settings config here. Also it's light-themed…
 */

type KpiOptionsType = ({
    label: string;
    value: KpiTypes | undefined;
} | {
    label: string;
    options: {
        label: string;
        value: KpiTypes;
    }[];
})[]

const ALL_EVENTS = "ALL_EVENTS";

const allKpiAggregationOptions: StringOption[] = [
    { label: "common.allCases", value: ALL_EVENTS },
    { label: "common.machine", value: GroupingKeys.Machine },
    { label: "common.machineType", value: GroupingKeys.MachineType },
    { label: "common.location", value: GroupingKeys.Location },
];

export function DashboardTileSettingsModal(props: Omit<ModalProps, "children"> & {
    onChange: (kpi: KpiTypes | undefined, statistic: StatisticTypes, quantity: BaseQuantityType | undefined, aggregation: GroupingKeys | undefined, workplace: string | undefined) => void,
    initialValue?: DashboadTileSettings;
}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const isOniqUser = isOniqEmployee(session);
    const isOniqDeveloper = isOniqDev(session);

    const showCarbon = hasCarbonDimension(session);

    const [kpiType, setKpiType] = useState<KpiTypes | undefined>(props.initialValue?.kpiType ?? settings.kpi.selectedKpi);

    const dimensionList = findKpiList(props.initialValue?.kpiType ?? settings.kpi.selectedKpi);

    //For every dimension we have specific list of kpis
    const [dimension, setDimension] = useState(dimensionList);

    const allowedKpis = useMemo(() => {
        return getAllAllowedKpisAnyStatistic(session, settings, dimension);
    }, [dimension]);


    // Carbon/Energy KPIs contain faked data so we don't want to show them to non-oniq users
    const kpis = !isOniqUser ? allowedKpis.filter(kpi => !["carbon", "carbonPerOutput", "energyPerOutput", "energy"].includes(kpi)) : allowedKpis;

    const kpiOptions: KpiOptionsType = kpis.map(v => {
        const kpiDefinition = getKpiDefinition(v, { session, settings });
        const kpiOption = { label: i18n.t(kpiDefinition?.label ?? ""), value: v, indentationLevel: kpiDefinition?.indentationLevel };
        if (kpiDefinition?.indentationLevel)
            return { label: "", options: [kpiOption] };
        return kpiOption;
    });

    const [kpiAggregation, setKpiAggregation] = useState<GroupingKeys | string>(props.initialValue?.kpiAggregation ?? ALL_EVENTS);

    const graphOptions = {
        calculateNodes: true,
        calculateRoles: true,
        calculateActivityValues: true,
    };

    const selectedGroupingKey = [GroupingKeys.Machine, GroupingKeys.MachineType, GroupingKeys.Location].find(i => i === kpiAggregation.toString());
    const graph = useGraph({ ...graphOptions, groupingKey: selectedGroupingKey, eventFilters: [] }, undefined, false, selectedGroupingKey === undefined);

    const [workplace, setWorkplace] = useState(props.initialValue?.workplace ?? undefined);

    const allowedStatistics = getAllowedStatistics(kpiType, kpiAggregation);
    const [statistic, setStatistic] = useState(() => {
        const candidate = props.initialValue?.statistic ?? settings.kpi.statistic;
        if (!allowedStatistics.includes(candidate))
            return allowedStatistics[0];
        return candidate;
    });

    const kpiDefinition = getKpiDefinition(kpiType, getContextFromTile({ kpiType, statistic }, session, settings));
    // If planning data exists, offer both quantities allowed for actual and plan. Otherwise,
    // only those from actual.
    const allowedQuantities = getAllowedQuantities(kpiType, statistic);

    const [quantity, setQuantity] = useState(() => {
        const candidate = props.initialValue?.quantity ?? settings.quantity ?? allowedQuantities[0]?.baseQuantity;
        if (allowedQuantities && !allowedQuantities.some(q => q.baseQuantity === candidate))
            return allowedQuantities[0]?.baseQuantity;
        return candidate;
    });

    const workplaceList = useMemo(() => {
        return kpiAggregation !== ALL_EVENTS ? getWorkplaces(kpiAggregation as GroupingKeys, graph) : [];

    }, [kpiAggregation, graph]);

    const hasEquipment: boolean = kpiDefinition?.equipmentOverTimeStatisticsPath !== undefined;
    const hasOnlyEquipment: boolean = hasEquipment &&
        kpiDefinition?.eventOverTimeStatisticsPath === undefined &&
        kpiDefinition?.productStatisticsPath === undefined;

    const aggregationOptions = allKpiAggregationOptions.map(o => {
        if (o.value === ALL_EVENTS && kpiDefinition?.timeperiodApi === TimeperiodApis.Event)
            return { ...o, label: i18n.t("common.allMachines") };
        return {value: o.value, label: i18n.t(o.label) };
    }).filter(o => { return !(o.value === ALL_EVENTS && hasOnlyEquipment) &&
         !(o.value === GroupingKeys.Location && session.project?.eventKeys?.location === undefined) &&
         !(o.value === GroupingKeys.MachineType && session.project?.eventKeys?.machineType === undefined); });

    return <Modal
        {...props}
        isClosable={true}
        isVisible={true}
        canBlur={true}
        isOkDisabled={!kpiType || (kpiAggregation !== ALL_EVENTS && !workplaceList.find(i => i.value === workplace))}
        title={i18n.t("dashboard.tileSelectionModal.title").toString()}
        showCancelButton={true}
        onDone={() => {
            const hasQuantity = allowedQuantities !== undefined && allowedQuantities.length > 0;
            const hasWorkplace = kpiAggregation !== ALL_EVENTS;
            props.onChange(kpiType, statistic, hasQuantity ? quantity : undefined, hasWorkplace ? kpiAggregation as GroupingKeys : undefined, hasWorkplace ? workplace : undefined);
            return Promise.resolve();
        }}
        width={500}>

        <div className="section">
            <div className="sectionTitle">
                {i18n.t("common.dimension")}
            </div>

            <div className={`${showCarbon ? "buttons-5col" : "buttons-4col"} mtLarge mb`}>
                <DimensionButton dimension={Dimensions.Timing} dimensionList={KpiPresets.dashboardTimeKpis} />
                <DimensionButton dimension={Dimensions.Output} dimensionList={KpiPresets.dashboardOutputKpis} />
                <DimensionButton dimension={Dimensions.Stock} dimensionList={KpiPresets.productInventoryKpis} />
                <DimensionButton dimension={Dimensions.Quality} dimensionList={KpiPresets.productQualityKpis} />
                {showCarbon && <DimensionButton dimension={Dimensions.Carbon} dimensionList={KpiPresets.productSustainabilityKpis} />}
            </div>
        </div>

        <div className="section">
            <div className="sectionTitle">
                {i18n.t("common.kpi")}
            </div>

            <Dropdown
                className="dropdownLight mb"
                options={kpiOptions}
                testId="dropdown-kpi"
                value={{ value: kpiType, label: i18n.t(getKpiDefinition(kpiType, { session, settings })?.label ?? "common.pleaseSelect") }}
                onChange={(e) => {
                    const kpi = e!.value as KpiTypes;
                    setKpiType(kpi);
                    const aggregation = getValidAggregation(kpiAggregation, kpi);
                    setKpiAggregation(aggregation);
                    const stat = getValidStatistic(statistic, kpi, aggregation);
                    setStatistic(stat);
                    const allowedQuantities = getAllowedQuantities(kpi, stat);
                    if (allowedQuantities?.length > 0 &&
                        (quantity === undefined || !allowedQuantities.some(q => q.baseQuantity === quantity)))
                        setQuantity(allowedQuantities[0].baseQuantity);
                }}
                isSearchable={true}
            />
        </div>

        <div className="section">

            <div className="buttons-3col mtLarge mb">
                <StatisticButton disabled={!allowedStatistics.includes(StatisticTypes.Mean)} statistic={StatisticTypes.Mean} />
                <StatisticButton disabled={!allowedStatistics.includes(StatisticTypes.Sum)} statistic={StatisticTypes.Sum} />
                <StatisticButton disabled={!allowedStatistics.includes(StatisticTypes.Median)} statistic={StatisticTypes.Median} />
            </div>

        </div>

        <>
            {hasEquipment && <>
                <div className="section">
                    <div className="sectionTitle">
                        {i18n.t("common.applyKpiTo")}
                    </div>
                    <Dropdown
                        className="dropdownLight mb"
                        options={aggregationOptions}
                        testId="dropdown-aggregation"
                        value={{ value: kpiAggregation, label: i18n.t(`${aggregationOptions.find(a => kpiAggregation === a.value)?.label}`) }}
                        onChange={(e) => {
                            const aggregation = e!.value as string;
                            setKpiAggregation(aggregation);
                            const stat = getValidStatistic(statistic, kpiType, aggregation);
                            setStatistic(stat);
                            setWorkplace(undefined);
                        }}
                        isSearchable={true}
                    />
                </div>

                <>
                    {kpiAggregation !== ALL_EVENTS &&
                        <div className="section">
                            <div className="sectionTitle">
                                {i18n.t(`common.${kpiAggregation}`)}
                            </div>
                            <Dropdown
                                className="dropdownLight mb"
                                options={workplaceList}
                                testId="dropdown-kpi"
                                value={workplaceList.find(i => i.value === workplace) ?? {
                                    label: i18n.t(graph?.nodes === undefined ? "common.initializing" : "common.pleaseSelect"),
                                    value: ""
                                }}
                                onChange={(e) => {
                                    setWorkplace(e!.value as string);
                                }}
                                isSearchable={true}
                            />
                        </div>
                    }
                </>
            </>}
        </>

        <>
            {allowedQuantities?.length > 0 && <QuantityDropdown
                quantities={allowedQuantities}
                hasSeparateSection={true}
                className="dropdownLight"
                value={quantity}
                onChange={q => setQuantity(q)} />}
        </>

        <>
            {isOniqDeveloper && <FormDeleteButton
                label={i18n.t("dashboard.deleteTile")}
                confirmationLabel="favorites.reallyDelete" 
                onClick={() => {
                    // This is a hack only for special purposes where we would like to show less tiles on the dashboard.
                    props.onChange(undefined, statistic, undefined, undefined, undefined);
                    return Promise.resolve();
                }} />
            }
        </>
    </Modal>;

    function getValidStatistic(statistic: StatisticTypes, kpiType: KpiTypes | undefined, aggregation: GroupingKeys | string) {
        if (!kpiType)
            return StatisticTypes.Mean;
        const allowedStatistics = getAllowedStatistics(kpiType, aggregation);

        if (!allowedStatistics)
            return StatisticTypes.Mean;

        if (allowedStatistics.includes(statistic))
            return statistic;

        return allowedStatistics[0];
    }

    function getAllowedStatistics(kpiType: KpiTypes | undefined, aggregation: GroupingKeys | string) {
        if (!kpiType)
            return [];
        const kpiDefinition = getKpiDefinition(kpiType, getContextOverride(session, settings, { grouping: aggregation as GroupingKeys }));
        const allowedStatistics = aggregation === ALL_EVENTS ?
            caseKpiControlsGetAllowedStatistics(session, settings, kpiType, AggregationTypes.Time) :
            [Object.keys(kpiDefinition?.equipmentOverTimeStatisticsPath ?? {})[0] as StatisticTypes];
        return allowedStatistics ? allowedStatistics : [];
    }

    function getValidAggregation(aggregation: GroupingKeys | string, kpiType: KpiTypes) {
        const kpiDefinition = getKpiDefinition(kpiType, getContextOverride(session, settings, { grouping: aggregation as GroupingKeys }));

        // If the aggregation is all events but the KPI does not support it, change to machine
        if (aggregation === ALL_EVENTS &&
            kpiDefinition?.eventOverTimeStatisticsPath === undefined &&
            kpiDefinition?.productStatisticsPath === undefined &&
            !!kpiDefinition?.equipmentOverTimeStatisticsPath)
            return GroupingKeys.Machine;

        // If the aggregation is not all events and the KPI does not support equipment statistics over time, change to all events
        if (aggregation !== ALL_EVENTS && kpiDefinition?.equipmentOverTimeStatisticsPath === undefined)
            return ALL_EVENTS;

        return aggregation;

    }

    function changeStatistic(stat: StatisticTypes) {
        if (statistic !== stat)
            setStatistic(stat);
        const allowedQuantities = getAllowedQuantities(kpiType, stat);
        if (kpiDefinition?.isQuantityDependent && allowedQuantities?.length > 0 && quantity === undefined)
            setQuantity(allowedQuantities[0].baseQuantity);
    }

    function StatisticButton(props: { statistic: StatisticTypes, disabled?: boolean }) {
        const id = `button-statistic-${props.statistic.toString().toLowerCase()}`;
        return <button
            disabled={props.disabled}
            className={statistic === props.statistic ? "active" : ""}
            id={id}
            data-testid={id}
            onClick={() => {
                changeStatistic(props.statistic);
            }}>
            {i18n.t(`common.statistics.${props.statistic}`)}
        </button>;
    }

    function DimensionButton(props: { dimension: Dimensions, dimensionList: KpiTypes[], disabled?: boolean }) {
        const id = `button-dimension-${props.dimension.toString().toLowerCase()}`;
        return <button
            disabled={props.disabled}
            className={dimension === props.dimensionList ? "active" : ""}
            id={id}
            data-testid={id}
            onClick={() => {
                setDimension(props.dimensionList);
                setKpiType(undefined);
            }}>
            {i18n.t(`common.${props.dimension}`)}
        </button>;
    }

    function getAllowedQuantities(kpiType: KpiTypes | undefined, statistic: StatisticTypes) {
        if (!kpiType)
            return [];

        const kpiDefinition = getKpiDefinition(kpiType, getContextFromTile({ kpiType, statistic }, session, settings));
        const allowedBaseQuantities: BaseQuantityType[] = (kpiDefinition?.allowedQuantities.actual.timeperiod ?? kpiDefinition?.allowedQuantities.actual.case ?? []).concat(session.project?.eventKeysPlan !== undefined ? kpiDefinition?.allowedQuantities.plan.case ?? [] : []);
        const allowedQuantities = uniq(allowedBaseQuantities).map(baseQuantity => quantities.find(q => q.baseQuantity === baseQuantity && !q.isFrequency)).
            filter(q => q !== undefined) as Quantity[];

        return allowedQuantities;
    }
}

export function getContextOverride(session: SessionType, settings: SettingsType, overrides: Partial<{
    statistic: StatisticTypes,
    kpi: KpiTypes,
    aggregation: AggregationTypes,
    quantity: BaseQuantityType,
    grouping: GroupingKeys,
}>) {
    return {
        session,
        settings: {
            ...settings,
            quantity: overrides.quantity ?? settings.quantity,
            grouping: overrides.grouping ?? settings.groupingKey,
            kpi: {
                ...settings.kpi,
                selectedKpi: overrides.kpi ?? settings.kpi.selectedKpi,
                statistic: overrides.statistic ?? settings.kpi.statistic,
                aggregation: overrides.aggregation ?? settings.kpi.aggregation,
            }
        },
    };
}

export function getContextFromTile(tile: DashboadTileSettings, session: SessionType, settings: SettingsType) {
    return getContextOverride(session, settings, {
        statistic: tile.statistic,
        kpi: tile.kpiType,
        aggregation: AggregationTypes.Time,
        quantity: tile.quantity,
        grouping: tile.workplace !== ALL_EVENTS ? tile.workplace as GroupingKeys : undefined,
    });
}

//change it here to get the correct kpi list
function getAllAllowedKpisAnyStatistic(session: SessionType, settings: SettingsType, kpis: KpiTypes[] | undefined) {
    const result: Set<KpiTypes> = new Set();

    if (!kpis)
        return [];

    for (const kpiType of kpis) {
        const allowedStatistics = caseKpiControlsGetAllowedStatistics(session, settings, kpiType, AggregationTypes.Time);
        if (!allowedStatistics)
            continue;

        for (const statistic of allowedStatistics) {
            const override = {
                aggregation: AggregationTypes.Time,
                statistic,
                kpi: kpiType,
            };
            const def = getKpiDefinition(kpiType, getContextOverride(session, settings, override));

            if (!def ||
                (def.requiresPlanningData && !session.project?.uploadIdPlan) ||
                (def.isQuantityDependent && def.allowedQuantities.actual.case.length === 0 && def.allowedQuantities.plan.case.length === 0) ||
                (def.requiredEventKeys !== undefined && def.requiredEventKeys.some(eventKey => get(session.project, eventKey) === undefined)) ||
                (def.requiredEventKeys !== undefined && def.allowedQuantities.actual.case.some(q => {
                    const kpiDef = getKpiDefinition(kpiType, getContextOverride(session, settings, {
                        ...override,
                        quantity: q,
                    }));
                    return kpiDef?.requiredEventKeys?.some(eventKey => get(session.project, eventKey) === undefined);
                })))
                continue;

            result.add(kpiType);
        }
    }

    return Array.from(result);
}

function getWorkplaces(selectedAggregation: GroupingKeys, graph: Graph | undefined) {

    const filterWorkplaces = (key: keyof NodeActivitySchema) => {
        return (graph?.nodes.filter(n => n.activityValues?.[key]?.value !== undefined && n.role !== NodeRoles.Inventory) ?? []).map(n => ({
            label: n.activityValues?.[key]?.value ?? "",
            value: n.activityValues?.[key]?.value ?? ""
        })).sort((a, b) => a.label.localeCompare(b.label));
    };

    let options: { label: string; value: string }[] = [];

    switch (selectedAggregation) {
        case GroupingKeys.Machine:
            options = filterWorkplaces("machine");
            break;

        case GroupingKeys.MachineType:
            options = filterWorkplaces("machineType");
            break;

        case GroupingKeys.Location:
            options = filterWorkplaces("location");
            break;
    }

    return uniqBy(options, (o) => o.label) as StringOption[];
}

function findKpiList(kpiType: KpiTypes) {

    const presetLists = [
        KpiPresets.dashboardTimeKpis,
        KpiPresets.dashboardOutputKpis,
        KpiPresets.productInventoryKpis,
        KpiPresets.dashboardQualityKpis,
        KpiPresets.productSustainabilityKpis
    ];

    for (const list of presetLists) {
        if (list.includes(kpiType)) {
            return list;
        }
    }

    return undefined;
}