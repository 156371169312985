import React, { useContext, useEffect } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { filterProduct } from "../../../hooks/UseProductCaseAggregations";
import i18n from "../../../i18n";
import { KpiDefinition, getCustomKpiParameters, getFiltersForKpis, getKpiDefinition } from "../../../models/Kpi";
import AggregatedCaseStats from "./AggregatedCaseStats";
import { KpiTypes } from "../../../models/KpiTypes";
import { getDefaultValueProp, getSort } from "../../../components/product-chart/ProductChart";
import { defaultProductLimit } from "../../../Global";
import { useProducts } from "../../../hooks/UseProducts";
import { KpiComparisons } from "../../../contexts/ContextTypes";

type ProductStatsProps = {
    kpiTypes: KpiTypes[];
    hasBusyTimeSubStats?: boolean;
    hasDelayTimeSubStats?: boolean;
    disableProductFilter?: boolean;
}

export default function ProductStats(props: ProductStatsProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const kpiDefinitions = props.kpiTypes.map(k => getKpiDefinition(k, { session, settings })).filter(k => k !== undefined) as KpiDefinition[];
    const kpiFilters = getFiltersForKpis(kpiDefinitions, settings, session);

    const kpiParams = getCustomKpiParameters(props.kpiTypes, settings, session, props.hasBusyTimeSubStats);

    const [productAggregations, isLoading] = useProducts(
        {
            ...(props.disableProductFilter ? {} : filterProduct(settings.selection.product, session, settings, kpiFilters)),
            ...kpiParams,
            calculatePlanned: settings.kpi.comparisons === KpiComparisons.Planning,
            sort: getSort(session, settings, getDefaultValueProp),
            limit: defaultProductLimit,
            calculateProductionStats: true,
            calculateTimeAndFreqStats: true,
        }, {
            disable: settings.selection.product === undefined
        });

    const selectedProduct = (productAggregations?.products ?? []).find(p => p.id === settings.selection.product?.id);

    useEffect(() => {
        if (isLoading ||
            settings.selection.product === undefined ||
            productAggregations?.products === undefined ||
            productAggregations?.products?.length !== 0 ||
            selectedProduct !== undefined)
            return;

        settings.setSelection({ product: undefined });

    }, [
        selectedProduct,
        isLoading,
        productAggregations,
    ]);

    if (!settings.selection.product)
        return null;

    return <AggregatedCaseStats
        title={`${i18n.t("common.product")} ${selectedProduct?.name}`}
        kpiDefinitions={kpiDefinitions}
        count={selectedProduct?.actual?.count ?? 0}
        isLoading={isLoading}
        selection={selectedProduct?.actual}
        selectionPlan={selectedProduct?.planned}
        hasBusyTimeSubStats={props.hasBusyTimeSubStats}
        hasDelayTimeSubStats={props.hasDelayTimeSubStats}
    />;

}
