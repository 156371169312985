import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spotlight } from "../../components/spotlight/Spotlight";
import Tile, { TileSelectionModes } from "../../components/tile/Tile";
import { SessionContext, isOniqEmployee } from "../../contexts/SessionContext";
import { SettingsContext, defaultValues } from "../../contexts/SettingsContext";
import Global from "../../Global";
import i18n from "../../i18n";
import { Formatter } from "../../utils/Formatter";
import { useLocalStorage } from "../../utils/LocalStorage";
import updateUserpilotUrl from "../../utils/Userpilot";
import { getDashboardSettings } from "../dashboard/Dashboard";
import Spinner from "../../components/spinner/Spinner";
import { useProjectsList } from "../../hooks/useProjectsList";


export default function ProjectBrowser() {
    const session = useContext(SessionContext);
    const settingsContext = useContext(SettingsContext);
    const [starMap, setStar] = useLocalStorage<boolean>("projectbrowser");
    const [projectsTable, isLoading] = useProjectsList();
    const [starMapState, setStarMapState] = useState<{[projectId: string]: boolean}>(starMap);

    const navigate = useNavigate();

    updateUserpilotUrl();

    if (isLoading)
        return <div className="fillParent">
            <Spinner isLoading={true} />
        </div>;

    return <div className="projectContainer">
        <div className="greetingContainer">
            <div className="greeting">{i18n.t("browser.welcome")}</div>
        </div>
        <div className="tileContainer" data-testid="container">
            {(projectsTable ?? []).map(e => <Tile
                key={e.id}
                className={e.project.id === session.project?.id ? "selected" : ""}
                selectionMode={TileSelectionModes.Pinnable}
                isSelected={starMap[e.id] === true}
                title={e.project.name}
                description={e.project.description}
                onSelectionChanged={(state) => {
                    updateStar(e.id, state);
                }}
                extra={
                    <table className="customTable">
                        <tbody>
                            <tr>
                                <td>
                                    {i18n.t("browser.states.updated")}
                                </td>
                                <td>
                                    {Formatter.formatDateTime(e.project?.updated, undefined, session.numberFormatLocale, session.timezone)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                onClick={() => {
                    session.setProject(e.project.id);
                    Global.projectLoadingSpinnerHasBeenShown = false;
                    navigate("/projects/" + e.project.id + "/dashboard");
                    settingsContext.set({
                        ...JSON.parse(JSON.stringify(defaultValues)),
                        dashboard: getDashboardSettings(session, e.project.id),
                    });
                }}
            />)}

            {isOniqEmployee(session) && <div>
                <div className="actionButtons">
                    <button
                        onClick={() => {
                            session.set({
                                project: undefined,
                                projectId: undefined,
                            });
                            navigate("/projects/add-project-upload");
                        }}>
                        <span className="addProject">
                            <svg className="svg-icon xsmall mr"><use xlinkHref="#radix-plus" /></svg>
                            <span>{i18n.t("browser.addProject")}</span>
                        </span>
                    </button>
                </div>
            </div>}
        </div>

        <Spotlight id="Projects" className="bottomRight mb mrl fixed" />
    </div>;

    function updateStar(id: string, state: boolean) {
        const newState = {...starMapState, [id]: state};

        setStarMapState(newState);
        setStar(id, state);
    }
}
