import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React, { useContext } from "react";
import { LegacyAnalyzedValues, RcaType } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useStatistics } from "../../hooks/UseStatistics";
import i18n from "../../i18n";
import { getQuantity } from "../../utils/Quantities";
import { StartRcaButton, submitRca } from "./RcaCreationUtils";

export function CreateOrgLossesRca() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    return <div className="rcaContent">
        <div className="infoText" dangerouslySetInnerHTML={{ __html: i18n.t("rca.organizationalLosses.explainer").toString() }} />

        <StartButton />
    </div>;

    function StartButton() {
        const [stats, areStatsLoading] = useStatistics();
        const { trackEvent } = useMatomo();

        return <StartRcaButton
            title="rca.startAnalysis"
            type={RcaType.Bottleneck}
            disabled={areStatsLoading}
            onClick={async () => {
                await submitRca(session,
                    settings,
                    RcaType.OrgLosses,
                    undefined,
                    "duration",
                    true,
                    LegacyAnalyzedValues.TimeValuesMean,
                    settings.filters,
                    `/projects/${session.projectId}/analyses/losses/organizational`,
                    stats.numFilteredTraces ?? 0,
                    (value: number | undefined, locale: string) => {
                        const formatter = getQuantity(settings.quantity, true)?.formatter;
                        if (formatter)
                            return formatter(value, undefined, locale);
                        return "";
                    },
                    undefined,
                    trackEvent,
                );
            }} />;
    }
}