import React, { useContext, useEffect, useMemo } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { NoDataAvailable } from "../../components/no-data-available/NoDataAvailable";
import Spinner from "../../components/spinner/Spinner";
import { TrayElement } from "../../components/tray/TrayElement";
import i18n from "../../i18n";
import { SelectionPage } from "../../components/selection-page/SelectionPage";
import { MachineSelector } from "../../components/controls/MachineSelector";
import { useLossReasons } from "../../hooks/UseLossReasons";
import { GroupingKeys } from "../../models/Dfg";
import { EventKeys } from "../../models/EventKeys";
import { Bar, HorizontalBars } from "../../components/horizontal-bars/HorizontalBars";
import { LossReasonsSchema, WallClockTimeStatisticsSchema } from "../../models/generated";
import { getKpiDefinition } from "../../models/Kpi";
import { Formatter } from "../../utils/Formatter";
import { KpiTypes } from "../../models/KpiTypes";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";

export function LossReasonsGraph() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const machineName = settings.kpiMatrix.machineName;
    const selectedKpi = getKpiDefinition(settings.kpi.selectedKpi, { settings, session });

    const unit = Formatter.units.durationShort;

    const loss = useMemo(() => {
        if (!selectedKpi || selectedKpi?.id === KpiTypes.FailureTime) return "failure";

        return selectedKpi?.id.toString();
    }, [selectedKpi]);

    const [data, isLoading] = useLossReasons({
        machines: machineName && machineName !== "all" ? [machineName] : undefined,
        losses: [loss],
        eventKeys: {
            ...session.project!.eventKeys,
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
    }, {
        disable:
            !session.project?.uploadId ||
            !session.project?.eventKeys?.reason

    });

    const reasons = data?.reasons;

    const kpi = selectedKpi?.id === KpiTypes.FailureTime ? "failure" : selectedKpi?.id.toString() ?? "";
    const dataKey = `${kpi}TimeStatistics` as keyof LossReasonsSchema;

    const sortedData = reasons?.sort((a: LossReasonsSchema, b: LossReasonsSchema) => {
        if (b[dataKey] === undefined) return -1;
        return ((b[dataKey] as WallClockTimeStatisticsSchema).total ?? 0) - ((a[dataKey] as WallClockTimeStatisticsSchema).total ?? 0);
    }) ?? [];

    const formattedData: Bar[] = sortedData.map((reason: LossReasonsSchema) => {
        const reasonLossSchema = reason[dataKey] as WallClockTimeStatisticsSchema;
        const value = reasonLossSchema?.total ?? 0;
        const formattedValue = unit.formatter(value, {
            locale: session.numberFormatLocale,
            baseQuantity: settings.quantity
        });

        return {
            label: reason.name,
            value: Math.floor((reasonLossSchema?.total ?? 0)),
            displayValue: formattedValue
        };
    }) ?? [];


    const onlyValues = formattedData.map((reason: Bar) => reason.value);
    const maxValue = Math.max(...onlyValues);
    const sum = onlyValues.reduce((partialSum, a) => partialSum + a, 0);
    const formattedSum = unit.formatter(sum, {
        locale: session.numberFormatLocale,
        baseQuantity: settings.quantity
    });

    useEffect(() => {
        settings.setSelection({
            lossReason: {
                ...settings.selection.lossReason,
                sumValueFormatted: formattedSum
            }
        });
    }, [sum]);

    const isEmpty = reasons?.length === 0;

    if (!isLoading && !settings.kpiMatrix.machineName)
        return <SelectionPage
            title={i18n.t("lossAnalysis.title").toString()}
            description={i18n.t("lossAnalysis.noMachineDescription").toString()}
            selectorComponent={
                <MachineSelector className="dropdownLight mbl sizeConstrainedSelect" canSelectAll={true} />
            } />;

    const startNewAnalysisButton = <div className="bottomLeft">
        <TrayElement>
            <button
                className="shortcutButton"
                style={{ order: -1001 }}
                onClick={() => {
                    settings.mergeSet({
                        kpiMatrix: {
                            machineName: ""
                        }
                    });
                }
                }>
                {i18n.t("lossAnalysis.startNewAnalysis").toString()}
            </button>
        </TrayElement>
        <BackButtonTrayElement />
    </div>;

    if (!isLoading && isEmpty)
        return <>
            <NoDataAvailable visible={true} title="lossAnalysis.errorTitle" message="lossAnalysis.noData" />
            {startNewAnalysisButton}
        </>;



    const selectedIndex = formattedData.findIndex((reason: Bar) => reason.label === settings.selection.lossReason?.name);

    return <div className="fillParentMatrix">
        <Spinner isLoading={isLoading} />
        {!isLoading && !isEmpty && data && <>
            <HorizontalBars
                onSelected={(value?: string, sumValueFormatted?: string) => {
                    settings.setSelection({ ...settings.selection, lossReason: { name: value, sumValueFormatted: sumValueFormatted ? sumValueFormatted : formattedSum } });
                }}
                title={i18n.t(`${selectedKpi?.label}`) ?? ""}
                selectedIndex={selectedIndex}
                maxValue={maxValue}
                legendLabel={i18n.t(`${selectedKpi?.label}`) ?? ""}
                verticalLinesCount={10}
                data={formattedData} />
        </>}
        {startNewAnalysisButton}
    </div>;
}