import { TimePeriodFrequencies } from "../models/ApiTypes";

type TimeRange = {
    start: Date,
    end: Date,
};

export type QuickFilterDefinition = {
    frequency: TimePeriodFrequencies,
    timeRange: (endDate: Date | undefined) => TimeRange | undefined,
};

export enum QuickFilterTypes {
    CurrentDay = "currentDay",
    LastDay = "lastDay",
    Rolling24Hours = "rolling24Hours",
    CurrentWeek = "currentWeek",
    LastWeek = "lastWeek",
    Rolling7Days = "rolling7Days",
    CurrentMonth = "currentMonth",
    LastMonth = "lastMonth",
    Rolling30Days = "rolling30Days",
    CurrentCalendarYear = "currentCalendarYear",
    LastCalendarYear = "lastCalendarYear",
    RollingYear = "rollingYear",
}

export const quickFilter = new Map<QuickFilterTypes, QuickFilterDefinition>();

quickFilter.set(
    QuickFilterTypes.CurrentDay,
    {
        frequency: TimePeriodFrequencies.Hour,
        timeRange: (date) => {
            return getCurrentDay(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.LastDay,
    {
        frequency: TimePeriodFrequencies.Hour,
        timeRange: (date) => {
            return getLastDay(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.Rolling24Hours,
    {
        frequency: TimePeriodFrequencies.Hour,
        timeRange: (date) => {
            return getRolling24Hours(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.CurrentWeek,
    {
        frequency: TimePeriodFrequencies.Day,
        timeRange: (date) => {
            return getCurrentWeek(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.LastWeek,
    {
        frequency: TimePeriodFrequencies.Day,
        timeRange: (date) => {
            return getLastWeek(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.Rolling7Days,
    {
        frequency: TimePeriodFrequencies.Day,
        timeRange: (date) => {
            return getRolling7Days(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.CurrentMonth,
    {
        frequency: TimePeriodFrequencies.Day,
        timeRange: (date) => {
            return getCurrentMonth(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.LastMonth,
    {
        frequency: TimePeriodFrequencies.Day,
        timeRange: (date) => {
            return getLastMonth(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.Rolling30Days,
    {
        frequency: TimePeriodFrequencies.Day,
        timeRange: (date) => {
            return getRolling30Days(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.CurrentCalendarYear,
    {
        frequency: TimePeriodFrequencies.Month,
        timeRange: (date) => {
            return getCurrentCalendarYear(date);
        },
    });


quickFilter.set(
    QuickFilterTypes.LastCalendarYear,
    {
        frequency: TimePeriodFrequencies.Month,
        timeRange: (date) => {
            return getLastCalendarYear(date);
        },
    });

quickFilter.set(
    QuickFilterTypes.RollingYear,
    {
        frequency: TimePeriodFrequencies.Month,
        timeRange: (date) => {
            return getRollingYear(date);
        },
    });

// #Region time range function definitions------------------------------------------------------------

function getCurrentMonth(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date);
    // We do not show the last day of the month in the UI
    end.setDate(end.getDate() - 1);
    end.setHours(23, 59, 59, 999);

    return { start, end };
}

function getLastMonth(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date.getFullYear(), date.getMonth(), 0);
    end.setHours(23, 59, 59, 999);

    return { start, end };
}

function getRolling30Days(date: Date | undefined) {
    if (date === undefined)
        return undefined; 

    const end = new Date(date);

    // Start date is 30 days before the given date
    const start = new Date(date);
    start.setDate(date.getDate() - 30);
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);
    // We do not show the last day of the month in the UI
    end.setDate(end.getDate() - 1);
 
    return { start, end };
}

function getCurrentCalendarYear(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    const start = new Date(date.getFullYear(), 0, 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date);
    // We do not show the last month of the year in the UI
    end.setFullYear(date.getFullYear(), end.getMonth(), 0);
    end.setHours(23, 59, 59, 999);

    return { start, end };
}

function getLastCalendarYear(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    const start = new Date(date.getFullYear() - 1, 0, 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date.getFullYear() - 1, 11, 31);
    end.setHours(23, 59, 59, 999);

    return { start, end };
}

function getRollingYear(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    const end = new Date(date);

    // Start date is one year before the given date
    const start = new Date(date);
    start.setFullYear(date.getFullYear() - 1, end.getMonth(), 1);
    start.setHours(0, 0, 0, 0);
    // We do not show the last month of the year in the UI
    end.setFullYear(date.getFullYear(), end.getMonth(), 0);
    end.setHours(23, 59, 59, 999);

    return { start, end };
}

function getRolling24Hours(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    const end = new Date(date);

    // Start date is 24 hours before the given date
    const start = new Date(date);
    start.setHours(date.getHours() - 24);
    start.setHours(start.getHours() + 1, 0, 0, 0);
    end.setHours(end.getHours() - 1, 59, 59, 999);

    return { start, end };
}

function getCurrentDay(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    const start = new Date(date);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date);
    end.setHours(end.getHours() - 1, 59, 59, 999);

    return { start, end };
}

function getLastDay(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    // Subtract one day from the given date
    const previousDate = new Date(date);
    previousDate.setDate(date.getDate() - 1);

    const start = new Date(previousDate);
    start.setHours(0, 0, 0, 0);

    const end = new Date(previousDate);
    end.setHours(23, 59, 59, 999);

    return { start, end };
}

function getCurrentWeek(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
    const start = new Date(date);

    // Adjust start date to the previous Monday
    start.setDate(date.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
    start.setHours(0, 0, 0, 0); // Set to the start of the day

    const end = new Date(date);
    // We do not show the last day of the week in the UI
    end.setDate(end.getDate() - 1);
    end.setHours(23, 59, 59, 999);

    return { start, end };
}

function getLastWeek(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    // Create a new date object to avoid mutating the original date
    const currentDate = new Date(date);

    // Calculate the previous Monday
    const dayOfWeek = currentDate.getDay();
    const prevMonday = new Date(currentDate);
    prevMonday.setDate(currentDate.getDate() - dayOfWeek - 6); // Adjust to the previous Monday
    prevMonday.setHours(0, 0, 0, 0);
 
    // Calculate the previous Sunday
    const prevSunday = new Date(prevMonday);
    prevSunday.setDate(prevMonday.getDate() + 6); // Adjust to the previous Sunday
    prevSunday.setHours(23, 59, 59, 999);
 
    return { start: prevMonday, end: prevSunday };
}

function getRolling7Days(date: Date | undefined) {
    if (date === undefined)
        return undefined;

    const end = new Date(date);

    // Start date is 7 days before the given date
    const start = new Date(date);
    start.setDate(date.getDate() - 7);

    start.setHours(0, 0, 0, 0);
    // We do not show the last day of the week in the UI
    end.setDate(end.getDate() - 1);
    end.setHours(23, 59, 59, 999);

    return { start, end };
}