import { flatten, isArray, isFinite } from "lodash";
import { ColumnValue } from "../models/ApiTypes";
import { attributeTypeId } from "../components/filters/editors/attribute-filter-editor/AttributeHelpers";
import { SessionType } from "../contexts/SessionContext";
import { ProductIdentifier, SettingsType } from "../contexts/SettingsContext";
import { GroupingKeys, Node } from "../models/Dfg";
import { EventFilter, activityFilterRenderType, caseIdFilterRenderType, isFilterEqual, productFilterRenderType, timeFilterRenderType } from "../models/EventFilter";
import { ActivityItem, EventKeys } from "../models/EventKeys";
import { groupingKeyToApi, groupMapping, groupSupportsConsolidatePasses } from "./GroupingUtils";

export function buildNumericAttributeRangeFilter(columnName: string | undefined, minValue: number | undefined, maxValue: number | undefined, session: SessionType): EventFilter | undefined {
    function toFilter(input: attributeTypeId | undefined, value: number | undefined, prefix: string) {
        if (input === undefined || value === undefined)
            return undefined;

        const filterName = {
            "string": "caseAttributeText",
            "integer": "caseAttributeInt",
            "float": "caseAttributeFloat",
            "datetime": "caseAttributeDatetime",
            "boolean": "caseAttributeBool",
        }[input];

        return {
            [filterName]: {
                name: columnMeta?.name,
                [prefix]: value,
            }
        };
    }

    if (columnName === undefined ||
        (minValue === undefined && maxValue === undefined))
        return undefined;

    const columnMeta = (session.eventUpload?.meta.attributes ?? []).find(c => c.name === columnName);
    const columnType: attributeTypeId = columnMeta?.type ?? "string";

    const result: EventFilter = {
        filters: [],
        caseAggregator: "any",
        mergeOperator: "and",
    };

    const minFilter = toFilter(columnType, minValue, "ge");
    if (minFilter)
        result.filters!.push(minFilter);

    const maxFilter = toFilter(columnType, maxValue, "le");
    if (maxFilter)
        result.filters!.push(maxFilter);

    return result;
}

export function buildTimeFilter(start: Date | undefined, end: Date | undefined, requireStartInRange: boolean, requireEndInRange: boolean, exclude: boolean) {
    if (start === undefined && end === undefined)
        return undefined;

    const isSimpleFilter = requireEndInRange || requireStartInRange;


    const complexFilterInclude: EventFilter = {
        renderType: timeFilterRenderType,
        filters: [start ? {
            caseTime: {
                ge: start.toISOString(),
                requireEndInRange: true,
                exclude: false,
            }
        } : undefined, end ? {
            caseTime: {
                lt: end.toISOString(),
                requireStartInRange: true,
                exclude: false,
            }
        } : undefined].filter(f => f !== undefined) as EventFilter[],
        mergeOperator: "and",
    };

    const complexFilterExclude: EventFilter = {
        renderType: timeFilterRenderType,
        filters: [start ? {
            caseTime: {
                lt: start.toISOString(),
                requireEndInRange: true,
                exclude: false,
            }
        } : undefined, end ? {
            caseTime: {
                ge: end.toISOString(),
                requireStartInRange: true,
                exclude: false,
            }
        } : undefined].filter(f => f !== undefined) as EventFilter[],
        mergeOperator: "or",
    };



    const result: EventFilter = isSimpleFilter ? {
        caseTime: {
            ge: start !== undefined ? start.toISOString() : undefined,
            lt: end !== undefined ? new Date(end.getTime() + 1000).toISOString() : undefined,
            requireEndInRange: requireEndInRange,
            requireStartInRange: requireStartInRange,
            exclude: exclude,
        }
    } : exclude ? complexFilterExclude : complexFilterInclude;

    return result;
}

export function getTimeFilterProps(filter: EventFilter | undefined) {
    if (!filter)
        return undefined;

    const dates = (((filter.filters as EventFilter[] | undefined)?.map(f => f.caseTime?.ge ?? f.caseTime?.lt) ?? []).concat([filter.caseTime?.ge, filter.caseTime?.lt]).filter(d => d !== undefined) as string[]).sort();
    const start = filter.caseTime?.ge ?? dates[0];
    const end = filter.caseTime?.lt ?? dates[1];
    const requireStartInRange = filter.caseTime?.requireStartInRange ?? false;
    const requireEndInRange = filter.caseTime?.requireEndInRange ?? false;
    const exclude = filter.mergeOperator === "or" || (filter.caseTime?.exclude ?? false);
    return { start, end, requireStartInRange, requireEndInRange, exclude };
}


export function buildReasonFilter(reason: ColumnValue | undefined, exclude: boolean, session: SessionType) {
    if (!reason?.value || !session.project || !session.eventUpload)
        return;

    // Get reason column type
    const columnInfo = (session.eventUpload.meta.attributes ?? []).find(c => c.name === session.project!.eventKeys?.reason);
    const columnName = columnInfo?.name;
    const columnType = columnInfo?.type ?? "string";

    // build attribute filter
    const type = getAttributeFilterTypeArray(columnType, reason.value);
    if (!type || !columnName)
        return undefined;

    const prefix = exclude ? "ne" : "eq";

    return {
        [type.filter]: {
            name: columnName,
            [prefix]: type.value,
            renderType: productFilterRenderType,
        }
    } as EventFilter;
}

/**
 * Builds a variant filter
 * @param variantIds Variant IDs to filter for
 * @param groupingKey Grouping Key
 * @param exclude true if you want to exclude the variant IDs provided, otherwise false
 * @returns EventFilter instance of undefined
 */
export function buildVariantFilter(variantIds: string[], groupingKey: GroupingKeys, exclude: boolean) {
    const prefix = exclude ? "ne" : "eq";

    const filter: EventFilter | undefined = variantIds.length === 0 ? undefined : {
        variant: {
            consolidatePasses: groupSupportsConsolidatePasses(groupingKey),
            activityKeysGroup: groupingKeyToApi(groupingKey),
            [prefix]: variantIds,
        }
    } as EventFilter;

    return filter;
}

/**
 * Builds an activity filter that filters for the activity specified
 * @param activities The activity to filter for
 * @param exclude true if you want to exclude that activity, false if you want to require it
 * @returns EventFilter instance
 */
export function buildActivityFilter(activities: ActivityItem | ActivityItem[], exclude: boolean, groupingKey: GroupingKeys): EventFilter | undefined {
    const prefix = exclude ? "ne" : "eq";

    return {
        filters: [
            {
                activity: {
                    [prefix]: isArray(activities) ? activities.map(a => fixActivityItem(a)) : [fixActivityItem(activities)],
                    groupingKey,
                },
            }],
        caseAggregator: exclude ? "all" : "any",
        renderType: activityFilterRenderType,
    };
}

/**
 * We've seen some machines lacking e.g. a location, and that resulted in API errors.
 * Davis is on a mission to fix that, but for the time being we prevent these errors
 * by providing dummy values for missing ones.
 *
 * TODO: Once issue #1099 is resolved, remove this function.
 * @param item ActivityItem to be fixed
 * @returns Fixed ActivityItem
 */
export function fixActivityItem(item: ActivityItem) {
    const keys = item.keys.filter((_, idx) => item.values[idx] !== undefined && item.values[idx] !== null);
    const result: ActivityItem = {
        id: item.id,
        keys,
        values: [],
    };

    for (const key of keys) {
        const keyIdx = item.keys.findIndex(k => k === key);
        result.values.push(item.values[keyIdx]);
    }

    return result;
}

/**
 * Builds a filter that filters for a given edge (= activity sequence)
 */
export function buildEdgeFilter(fromNode: Node, toNode: Node, exclude: boolean, groupingKey: GroupingKeys): EventFilter | undefined {
    const mapping = groupMapping.find(m => m.groupKey === groupingKey);
    if (!mapping || !fromNode.activityValues || !toNode.activityValues)
        return;

    const prefix = exclude ? "ne" : "eq";

    const definedAttributes = mapping.attributes.filter(a =>
        fromNode.activityValues![a] !== undefined &&
        !isFalsy(fromNode.activityValues![a]?.value) &&
        toNode.activityValues![a] !== undefined &&
        !isFalsy(toNode.activityValues![a]?.value));

    return {
        caseSequence: {
            groupingKey,
            enforceImmediateSequence: true,
            consolidatePasses: groupSupportsConsolidatePasses(groupingKey),
            [prefix]: [[{
                keys: definedAttributes,
                values: definedAttributes.map(a => fromNode.activityValues![a]!.value) as string[],
                id: fromNode.id,
            }, {
                keys: definedAttributes,
                values: definedAttributes.map(a => toNode.activityValues![a]!.value) as string[],
                id: toNode.id,
            }]]
        }
    } as EventFilter;
}

/**
 * Builds a product category filter that scans for specific values of a category column
 * @param category Category
 * @param categoryValue Required (or forbidden) category values
 * @param exclude Require of exclude category values
 * @param session Current session context. Used to check column names and event keys
 * @returns EventFilter instance or undefined in case of missing data
 */
export function buildProductCategoryFilter(category: string, categoryValues: string[], exclude: boolean, session: SessionType): EventFilter | undefined {
    const categoryType = session?.eventUpload?.meta.attributes.find(a => a.name === category)?.type;

    if (!categoryType || !categoryValues)
        return undefined;

    const prefix = exclude ? "ne" : "eq";

    const type = getAttributeFilterTypeArray(categoryType, categoryValues);
    if (!type)
        return undefined;

    return {
        renderType: productFilterRenderType,
        mergeOperator: exclude ? "or" : "and",
        caseAggregator: exclude ? "all" : "any",
        filters: [{
            [type.filter]: {
                name: category,
                [prefix]: type.value,
                renderType: productFilterRenderType,
            }
        }]
    } as EventFilter;
}

/**
 * Builds an attribute filter
 * @param column The column name
 * @param value The value you want to filter for
 */
export function buildAttributeFilter(session: SessionType, columnName: string, value: string, exclude: boolean): EventFilter | undefined {
    const columnType = session?.eventUpload?.meta.attributes.find(a => a.name === columnName)?.type;
    if (!columnType)
        return undefined;

    const arr = getAttributeFilterTypeArray(columnType, value);
    if (!arr || !arr.filter)
        return undefined;

    const prefix = exclude ? "ne" : "eq";

    return {
        [arr?.filter]: {
            name: columnName,
            [prefix]: arr.value
        }
    };
}

/**
 * Builds an attribute filter that filters for a specific product
 * @param productIds IDs of the products to filter for
 * @param exclude set to true if you want to exclude the product ID specified, or to false if you
 * want to remove everything else
 * @param session Current session context. Used to check column names and event keys
 * @param includeUpstream If true, upstream products are included in the filter, otherwise not
 * @returns EventFilter instance or undefined, if session is not initialized properly
 */
export function buildProductFilter(products: ProductIdentifier | ProductIdentifier[] | undefined, exclude: boolean, session: SessionType, includeUpstream = false): EventFilter | undefined {
    const productIdColumn = session.project?.eventKeys?.product;
    const productIdType = session?.eventUpload?.meta.attributes.find(a => a.name === productIdColumn)?.type;

    if (!products || !productIdType || !productIdColumn)
        return undefined;

    const prefix = exclude ? "ne" : "eq";

    const type = getAttributeFilterTypeArray(productIdType, isArray(products) ? products.map(p => p.name) : products.name);

    if (!type || !type.value)
        return undefined;

    const filter: EventFilter = {
        [type.filter]: {
            name: productIdColumn,
            [prefix]: type.value,
            billOfMaterials: includeUpstream ? { upstreamLevels: 1 } : undefined,
        }
    };

    return {
        caseAggregator: "any",
        filters: [filter],
        mergeOperator: "and",
        renderType: productFilterRenderType,
    } as EventFilter;
}

/**
 * Checks if the filter provided is a product filter. If so, it returns
 * the names of the products, undefined otherwise.
 * @param filter The filter to check
 * @param exclude If true, the filter is checked for exclusion, if false, it is checked for inclusion
 * @returns Product name array or undefined
 */
export function isProductFilter(filter: EventFilter | undefined, exclude: boolean | undefined, eventKeys: EventKeys | undefined) {
    if (!filter ||
        eventKeys === undefined ||
        filter.renderType !== productFilterRenderType ||
        filter.filters?.length !== 1)
        return undefined;

    const prefixes = exclude === false ? ["eq"] : exclude === true ? ["ne"] : ["eq", "ne"];

    const inner = filter.filters[0] as EventFilter;

    const columnName = inner.caseAttributeText?.name ?? inner.caseAttributeInt?.name ?? inner.caseAttributeFloat?.name;
    if (!columnName || columnName !== eventKeys.product)
        return undefined;

    if (inner.caseAttributeText)
        return (flatten(prefixes.map(p => {
            const prefixed = inner.caseAttributeText![p];
            return isArray(prefixed) ? prefixed.map(i => i.toString()) : prefixed?.toString();
        })).filter(p => p !== undefined)) as string[] | undefined;

    if (inner.caseAttributeInt)
        return (flatten(prefixes.map(p => {
            const prefixed = inner.caseAttributeInt![p];
            return isArray(prefixed) ? prefixed.map(i => i.toString()) : prefixed?.toString();
        })).filter(p => p !== undefined)) as string[] | undefined;

    if (inner.caseAttributeFloat)
        return flatten(prefixes.map(p => {
            const prefixed = inner.caseAttributeFloat![p];
            return isArray(prefixed) ? prefixed.map(i => i.toString()) : prefixed?.toString();
        })).filter(p => p !== undefined) as string[] | undefined;

    return undefined;
}

export function buildMachineFilter(node: Node | undefined, exclude: boolean): EventFilter | undefined {
    if (!node)
        return undefined;

    return buildNodeFilter(node, exclude, GroupingKeys.Machine);
}

export function buildMachinesFilter(nodes: Node[] | undefined, exclude: boolean): EventFilter | undefined {
    if (!nodes || nodes.length === 0)
        return undefined;

    const filters = nodes.map(n => buildMachineFilter(n, exclude)).filter(f => f !== undefined) as EventFilter[];
    if (filters.length === 0)
        return undefined;

    return {
        caseAggregator: exclude ? "all" : "any",
        filters,
        mergeOperator: "or",
    };
}

/**
 * Builds a filter based on the activityValues provided.
 * @param node Node to filter for
 * @param exclude set to true if you want to exclude the activityValues specified, or to false if you
 * want to remove everything else
 * @param groupingKey The current grouping key. Most commonly this will be settingsContext.groupingKey
 * @returns EventFilter instance or undefined if groupingKey is unknown.
 */
export function buildNodeFilter(node: Node | undefined, exclude: boolean, groupingKey: GroupingKeys): EventFilter | undefined {
    const mapping = groupMapping.find(m => m.groupKey === groupingKey);
    if (!node || !mapping || !node.activityValues)
        return;

    const prefix = exclude ? "ne" : "eq";

    const mappingAttributes = mapping.attributes.filter(a => node.activityValues &&
        node.activityValues![a] !== undefined &&
        !isFalsy(node.activityValues![a]?.value));
    const attributeValues = mappingAttributes.map(a => node.activityValues![a]?.value) as string[];

    return {
        renderType: activityFilterRenderType,
        caseAggregator: exclude ? "all" : "any",
        filters: [{
            activity: {
                groupingKey,
                [prefix]: [{
                    id: node.id,
                    keys: mappingAttributes,
                    values: attributeValues,
                }].map(a => fixActivityItem(a))
            }
        }]
    };
}

// export function isProductFilter(filter: EventFilter | undefined, exclude: boolean | undefined, eventKeys: EventKeys | undefined) {
export function isCaseFilter(filter: EventFilter | undefined, session: SessionType): undefined | string[] {
    if (filter?.renderType !== caseIdFilterRenderType || session.project?.eventKeys === undefined)
        return undefined;

    // we have a case filter. extract the case IDs!
    const caseIdColumn = session.project?.eventKeys?.caseId;
    const caseIdType = session?.eventUpload?.meta.attributes.find(a => a.name === caseIdColumn)?.type;

    switch (caseIdType) {
        case "string": return [...(filter?.caseAttributeText?.ne ?? filter?.caseAttributeText?.eq ?? [])];
        case "integer": return [...(filter?.caseAttributeInt?.ne ?? filter?.caseAttributeInt?.eq ?? [])].map(v => v.toString());
        case "float": return [...(filter?.caseAttributeFloat?.ne ?? filter?.caseAttributeFloat?.eq ?? [])].map(v => v.toString());
        case "datetime": return [...(filter?.caseAttributeDatetime?.ne ?? filter?.caseAttributeDatetime?.eq ?? [])];
        case "boolean": return [...(filter?.caseAttributeBool?.ne ?? filter?.caseAttributeBool?.eq ?? [])].map(v => v.toString());
        default: return undefined;
    }
}

/**
 * Builds a filter for specific case IDs. It either removes the case IDs specified (exclude = true) or
 * just lets these pass (exclude = false)
 * @param caseIds Case IDs to filter for
 * @param exclude set to true if you want to exclude the case IDs specified, or to false if you
 * want to remove everything else
 * @param session Current session context. Used to check column names and event keys
 * @returns EventFilter instance or undefined, if session is not initialized properly
 */
export function buildCaseFilter(caseIds: string[] | undefined, exclude: boolean, session: SessionType): EventFilter | undefined {
    const caseIdColumn = session.project?.eventKeys?.caseId;
    const caseIdType = session?.eventUpload?.meta.attributes.find(a => a.name === caseIdColumn)?.type;

    if (!caseIdType || !caseIdColumn || !caseIds?.length)
        return undefined;

    const prefix = exclude ? "ne" : "eq";

    // Build a filter according to the type
    switch (caseIdType) {
        case "string":
            return {
                renderType: caseIdFilterRenderType,
                caseAttributeText: {
                    name: caseIdColumn,
                    [prefix]: caseIds,
                }
            };

        case "integer":
            return {
                renderType: caseIdFilterRenderType,
                caseAttributeInt: {
                    name: caseIdColumn,
                    [prefix]: caseIds.map(v => +v),
                }
            };

        // The following cases are not really expected to happen, but the world is big and who knows.
        case "float":
            return {
                renderType: caseIdFilterRenderType,
                caseAttributeFloat: {
                    name: caseIdColumn,
                    [prefix]: caseIds.map(v => +v),
                }
            };

        case "datetime":
            return {
                renderType: caseIdFilterRenderType,
                caseAttributeDatetime: {
                    name: caseIdColumn,
                    [prefix]: caseIds.map(v => v),
                }
            };

        case "boolean":
            return {
                renderType: caseIdFilterRenderType,
                caseAttributeBool: {
                    name: caseIdColumn,
                    [prefix]: caseIds.map(v => v.toLowerCase() === "true"),
                }
            };

        default:
            throw Error("category type not implemented: " + caseIdType);
    }
}

type AttributeFilterType = {
    filter: string;
    value: string[];
} | {
    filter: string;
    value: number[] | undefined;
} | {
    filter: string;
    value: boolean[];
} | undefined;


function getAttributeFilterTypeArray(type: attributeTypeId, value: string | string[]): AttributeFilterType {
    function validateNumeric(arr: number[]) {
        const isValid = arr.every(v => isFinite(v));
        return isValid ? arr : undefined;
    }

    const isValueArray = isArray(value);
    const valueArr = value as string[];

    const map = new Map([
        ["string", {
            filter: "caseAttributeText",
            value: isValueArray ? valueArr : [value],
        }],
        ["integer", {
            filter: "caseAttributeInt",
            value: validateNumeric(isValueArray ? valueArr.map(v => +v) : [+value]),
        }],
        ["float", {
            filter: "caseAttributeFloat",
            value: validateNumeric(isValueArray ? valueArr.map(v => +v) : [+value]),
        }],
        ["datetime", {
            filter: "caseAttributeDatetime",
            value: isValueArray ? valueArr : [value],
        }],
        ["boolean", {
            filter: "caseAttributeBool",
            value: isValueArray ? valueArr.map(v => v.toString().toLowerCase() === "true") : [value.toString().toLowerCase() === "true"],
        }]
    ]);

    const result = map.get(type);
    return !result?.value ? undefined : result as AttributeFilterType;
}

/**
 * Returns the settings provided in that way, that the filter is inserted at the index specified
 * @param settings Current settings to insert into
 * @param filter The filter to insert
 * @param filterIndex If -1, the filter will be inserted at the end, otherwise at the index provided
 */
export function insertFilterAt(settings: SettingsType, filter: EventFilter, filterIndex: number) {
    const insertHelper = (filter: EventFilter, array: EventFilter[], filterIndex: number) => {
        // Check if filter is a duplicate
        if (isDuplicate(array, filter))
            return array;

        const head = array.filter((_, idx) => idx < filterIndex);
        const tail = array.filter((_, idx) => idx >= filterIndex);

        return head.concat([filter]).concat(tail);
    };

    const idx = filterIndex < 0 ? settings.filters.length : Math.min(settings.filters.length, filterIndex);

    return {
        ...settings,
        filters: insertHelper(filter, settings.filters, idx),
        previewFilters: settings.previewFilters === undefined ? undefined : insertHelper(filter, settings.previewFilters, idx),
        filterEditor: {
            ...settings.filterEditor,
            editFilterIndex: settings.filterEditor.editFilterIndex === undefined ? undefined :
                settings.filterEditor.editFilterIndex < idx ? settings.filterEditor.editFilterIndex : settings.filterEditor.editFilterIndex + 1,
        },
    } as SettingsType;
}

/**
 * Checks if the filter provided is already included in the filters
 * array. Returns true if that's the case, otherwise false.
 * @param filters The filters search duplicates in
 * @param filter The filter to check if it is contained in the filters array
 */
export function isDuplicate(filters: EventFilter[], filter: EventFilter) {
    const result = filters.some(f => {
        return isFilterEqual(f, filter);
    });

    return result;
}


function isFalsy(v: any) {
    return v === null || v === undefined;
}

export function isUpstreamProductFilter(f: EventFilter, eventKeys: EventKeys | undefined) {
    const isProduct = isProductFilter(f, false, eventKeys);
    const attributeFilter = f?.filters?.[0]?.caseAttributeText ?? f?.filters?.[0]?.caseAttributeInt ?? f?.filters?.[0]?.caseAttributeFloat ?? f?.filters?.[0]?.caseAttributeBool;
    return !!isProduct?.length && attributeFilter?.billOfMaterials?.upstreamLevels !== undefined;
}

export function getCaseFilter(f: EventFilter | undefined) {
    return f?.caseAttributeText ?? f?.caseAttributeInt ?? f?.caseAttributeFloat ?? f?.caseAttributeBool ?? f?.caseAttributeDatetime;
}
