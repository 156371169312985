import React, { useContext } from "react";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import i18n from "../../i18n";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { KpiComparisons } from "../../contexts/ContextTypes";
import {
    KpiControls,
    getValidKpiControlSettings,
} from "../../components/controls/KpiControls";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";

import { ObjectMerger } from "../../utils/ObjectMerger";
import { MachineSelector } from "../../components/controls/MachineSelector";

import { LossReasonsGraph } from "../loss-reasons/LossReasonsGraph";
import { LossReasonsStatistics } from "../loss-reasons/LossReasonsStatistics";

export function WorkplaceLossAnalysis() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const machineName = settings.kpiMatrix.machineName;
    const lossReason = settings.selection.lossReason;
    const machineNameLabel = machineName && machineName !== "all" ? machineName : i18n.t("common.allMachines").toString();
    const lossSum = settings.selection.lossReason?.sumValueFormatted ?? "";

    const breadcrumbs = [
        {
            label: "common.analyses",
            url: `/projects/${session.projectId}/analyses`,
        },
        {
            label: "common.analysesView.lossOverview",
        },
    ];

    return (
        <TabbedView
            enableOnlyTimespan={true}
            subtitle={<MachineSubtitleDropdown />}
            breadcrumbs={breadcrumbs}
            pages={[
                {
                    tabTitle: "",
                    tabSlug: "analysis",
                    selectionTrigger: (settings) => {
                        return settings.selection.lossReason?.name !== undefined;
                    },
                    spotlightId: "Analyses-Workplace-Loss",
                    activator: (preferences) => {
                        const temp = viewSettingsInitialization(
                            session,
                            settings,
                            preferences,
                            { kpi: { comparisons: KpiComparisons.None } },
                            {
                                kpis: [settings.kpi.selectedKpi ?? KpiTypes.FailureTime],
                                statistics: [StatisticTypes.Sum],
                            }
                        );

                        if (!temp) return;

                        settings.mergeSet(
                            ObjectMerger.mergeObjects([
                                temp,
                                getValidKpiControlSettings(session, settings, {
                                    kpis: [KpiTypes.FailureTime, KpiTypes.TechnicalLosses, KpiTypes.ProcessLosses, KpiTypes.QualityLosses, KpiTypes.OrganizationalLosses],
                                    hideStatistics: [StatisticTypes.Sum, StatisticTypes.Variance, StatisticTypes.Mean, StatisticTypes.Median],
                                }),
                            ])
                        );
                    },
                    controller: <div className="controls">
                        <KpiControls
                            kpis={[KpiTypes.FailureTime, KpiTypes.TechnicalLosses, KpiTypes.ProcessLosses, KpiTypes.QualityLosses, KpiTypes.OrganizationalLosses]}
                            hideStatistics={[StatisticTypes.Variance, StatisticTypes.Mean, StatisticTypes.Sum, StatisticTypes.Median]}
                            kpiOverrides={[
                                { kpiType: KpiTypes.FailureTime, overrides: { indentationLevel: undefined } },
                                { kpiType: KpiTypes.TechnicalLosses, overrides: { indentationLevel: 0 } },
                                { kpiType: KpiTypes.ProcessLosses, overrides: { indentationLevel: 0 } },
                                { kpiType: KpiTypes.QualityLosses, overrides: { indentationLevel: 0 } },
                                { kpiType: KpiTypes.OrganizationalLosses, overrides: { indentationLevel: 0 } },
                            ]}
                        />
                    </div>,
                    stats: machineName ? <LossReasonsStatistics sum={lossSum} reason={lossReason?.name} machineName={machineNameLabel} /> : undefined,
                    isVisible: true,
                    content: (
                        <LossReasonsGraph />
                    ),

                },
            ]}
        />
    );
}


function MachineSubtitleDropdown() {
    const settings = useContext(SettingsContext);

    const [showTitle, setShowTitle] = React.useState(true);

    return !settings.kpiMatrix.machineName ? (
        <div className="fixedHeight text">
            {i18n.t("common.workplaceSelection").toString()}
        </div>
    ) : (
        <>
            {showTitle && (
                <div
                    className="fixedHeight text"
                    onClick={() => setShowTitle(false)}
                    style={{ cursor: "pointer" }}
                >
                    {settings.kpiMatrix.machineName === "all" ? i18n.t("common.allMachines") : settings.kpiMatrix.machineName}
                    <svg className="svg-icon xxsmall rotate180 ml">
                        <use xlinkHref="#radix-chevron-up"></use>
                    </svg>
                </div>
            )}
            {!showTitle && (
                <div className="fixedHeight">
                    <MachineSelector
                        focusInitially={true}
                        bare={true}
                        onChange={() => setShowTitle(true)}
                        onBlur={() => setShowTitle(true)}
                        canSelectAll={true}
                        hidePlaceHolder={true}
                    />
                </div>
            )}
        </>
    );
}
