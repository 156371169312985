import { useContext } from "react";
import { Datastores } from "../utils/Datastores";
import { ApiHookOptions, useApi } from "./UseApi";
import { SessionContext } from "../contexts/SessionContext";
import { GetUploadsRequest } from "../models/ApiTypes";
import { Upload } from "../models/Upload";

export function useUploads(request: Partial<GetUploadsRequest> = {}, options?: ApiHookOptions<Upload[]>) {
    const session = useContext(SessionContext);

    const setDisabled =
        options?.disable || session.user === undefined;

    return useApi(
        Datastores.getUploads,
        request,
        [JSON.stringify(options)],
        { ...options, disable: setDisabled });
}