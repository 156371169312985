import { SetupMatrixTypes } from "./ApiTypes";
import { GroupingKeys } from "./Dfg";
import { EventFilter } from "./EventFilter";
import { EventKeys } from "./EventKeys";
import { KpiDefinition } from "./Kpi";
import { KpiTypes } from "./KpiTypes";

export type UploadBillOfMaterials = {
    id: string;
    columnMapping: {
        bomId: string;
        product: string;
        componentId: string;
        componentPosition: string;
        componentRank: string;
        componentMass: string;
        componentLength: string;
        componentCount: string;
    };
};

export type UploadCases = {
    id: string;
    columnMapping: {
        caseId: string;
        startTime?: string,
        endTime?: string;
        internalDueDate?: string;
        customerDueDate?: string;
        yieldLength?: string;
        yieldCount?: string;
        yieldMass?: string;
        scrapLength?: string;
        scrapCount?: string;
        scrapMass?: string;
        product?: string;
        productCategories?: string[],
        plannedYieldMass?: string,
        plannedYieldLength?: string,
        plannedYieldCount?: string,
        plannedScrapMass?: string,
        plannedScrapLength?: string,
        plannedScrapCount?: string,
        plannedElectricityEnergy?: string,
        plannedGasEnergy?: string,
        plannedCarbonMass?: string,
        [key: string]: string[] | string | undefined;
    }
};

export type UploadOrganizationShiftCalendar = {
    id: string;
    columnMapping: {
        startTime: string;
        endTime: string;
    }
};

export type UploadRoutings = {
    id: string;
    columnMapping: {
        operation?: string;
        machine?: string;
        machineType?: string;
        location?: string;
        routingId: string;
        passId: string;
        productionTimePerUnit?: string;
        productionTimePerBatch?: string;
        productionTimePerPass?: string;
        setupTimePerUnit?: string;
        setupTimePerBatch?: string;
        setupTimePerPass?: string;
        batchSize?: string;
        utilizationRate?: string;
        baseQuantity?: string;
        scrapRatio?: string;
        objectType?: string;
    }
};

export type UploadsType = {
    billOfMaterials?: UploadBillOfMaterials;
    cases?: UploadCases;
    routings?: UploadRoutings;
    shiftCalendar?: {
        id: string;
        columnMapping: {
            machine: string;
            shift: string;
            startTime: string;
            endTime: string;
        }
    },
    maintenanceCalendar?: {
        id: string;
        columnMapping: {
            machine: string;
            shift: string;
            startTime: string;
            endTime: string;
        }
    },
    organizationShiftCalendar?: {
        id: string;
        columnMapping: {
            startTime: string;
            endTime: string;
        }
    },
    plannedPasses?: {
        id: string;
        columnMapping: {
            caseId: string;
            passId: string;
            startTime: string;
            endTime: string;
        }
    },
};

export type BottleneckFactorWeights = {
    [key: string]: number;
}

type BottleneckTypes = "global" | "product";

export enum EventStatisticsTypes {
    Events = "events",
    Equipment = "equipment"
}

export type Project = {
    id: string;
    userId: string;
    created?: Date;
    updated?: Date;
    name: string;
    description?: string;
    uploadId?: string;
    eventKeys?: EventKeys;
    eventFilters?: EventFilter[];
    uploadIdPlan?: string;
    eventKeysPlan?: EventKeys;
    uploadIdOrderTracking?: string;
    /**
     * The settings are not strictly typed itself. Basically any key-value pair can be added here.
     * In case you add new settings here, please also add this information to the [API documentation](../../../../backend/excavator/excavators/schemas/projects.py).
     */
    settings?: Partial<{
        /**
         * The type of setup matrix to use for this project.
         * Please see the setup matrix endpoint for more information on the possible types.
         */
        setupMatrixType?: SetupMatrixTypes;
        /**
         * Determines whether it is a project used for demo purposes.
         */
        isDemoProject?: boolean;

        /**
         * Customer-specific KPI definitions that override the default KPI definitions.
         * Currently, only properties that end on ...Path as well as customKpis are supported.
         */
        customerKpis?: {[kpiType in KpiTypes]?: Partial<KpiDefinition>};

        /**
         * RCA weights indicators to be use for this project.
         */
        bottleneckFactorWeights?: BottleneckFactorWeights;

        /**
         * The bottleneck type to use for this project.
         * Currently the bottleneck can be global (all products) or per product.
         */
        bottleneckType?: BottleneckTypes;

        /**
         * The grouping to use for the bottleneck analysis.
         * The only exception is when the bottleneckType is set to product, then the grouping is always pass.
         */
        bottleneckGrouping?: GroupingKeys;

        /**
         * The determines the type of events endpoint to hit.
         * "events" means we use the TimeperiodApi.Events and 
         * "equipment" means we use the TimeperiodApi.Equipment.
         * By default we will be using the "events" endpoint.
         */
        eventStatisticsType?: EventStatisticsTypes;

    }> | undefined;
    eventKeysOrderTracking?: EventKeys;
    isSharedWithOrganization?: boolean;
    features?: {
        /** 
         * New feature flags for the project need to be added in the excavator also. 
         * You need to add the changes to the following file: 
         * backend/excavator/excavator/projects/schemas.py -> UiFeaturesSchema
         */

        /**
         * Show the bottleneck analysis in a project. If no value is supplied it will still show the bottleneck analysis.
         */
        allowBottleneckRca?: boolean;
        allowThroughputRca?: boolean;
        allowScheduleDeviationRca?: boolean,
        allowThroughputTimeRca?: boolean,
        allowQualityRca?: boolean,
        allowSetupMatrix?: boolean,
        allowSetupEvents?: boolean,
        allowOrganizationalLossesAnalysis?: boolean,
        allowMasterDataDownload?: boolean,

        /**
         * When set to true this will activate the net transition time and also net throughput time being an option as new KPI.
         */
        allowNetTimes?: boolean,

        /**
         * Show the takt time analysis in a project. If no value is supplied it will still show the takt time analysis.
         */
        allowTaktTime?: boolean;

        /**
         * When set to true show the equipment comparison (oee comparison) analysis in a project.
         */
        allowEquipmentComparison?: boolean;

        /**
         * When set to false this will disable the process workplace comparison
         */
        allowProcessWorkplaceComparison?: boolean;

    },
    uploads?: UploadsType,
}
