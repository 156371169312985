import React, { useContext, useEffect } from "react";
import { TimePeriodFrequencies, BaseQuantityType } from "../../models/ApiTypes";
import { AggregationTypes, KpiComparisons } from "../../contexts/ContextTypes";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, SortByType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { Quantity } from "../../utils/Quantities";
import Dropdown, { StringOption } from "../dropdown/Dropdown";
import { Spotlight } from "../spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { hasProductCustomKpi } from "../../models/Kpi";
import { SortOrder, StatisticTypes } from "../../models/KpiTypes";
import { DeepPartial } from "../../utils/ObjectMerger";
import { set } from "lodash";

export const timeTranslations = [
    { label: "units.hours", value: TimePeriodFrequencies.Hour },
    { label: "units.days", value: TimePeriodFrequencies.Day },
    { label: "units.weeks", value: TimePeriodFrequencies.Week },
    { label: "units.months", value: TimePeriodFrequencies.Month },
    { label: "units.years", value: TimePeriodFrequencies.Year }
];

type AggregationControlProps = {
    showTime?: boolean;
    disableOrders?: boolean;
};

export function getValidAggregationControlSettings(session: SessionType, settings: SettingsType, props: AggregationControlProps) {
    const result: DeepPartial<SettingsType> = { };

    const isProductDefined = session.project?.eventKeys?.product !== undefined;
    if (!session.project)
        return result;

    if ((!isProductDefined && settings.kpi.aggregation === AggregationTypes.Product) ||
        (!props.showTime && settings.kpi.aggregation === AggregationTypes.Time))
        set(result, "kpi.aggregation", AggregationTypes.Case);

    return result;
}

export function AggregationControls(props: AggregationControlProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const location = useLocation();

    const isProductDefined = session.project?.eventKeys?.product !== undefined;
    if (!isProductDefined)
        return <></>;

    const timeScaleOptions = timeTranslations.map(t => { return { ...t, label: i18n.t(t.label) }; });

    return <div className="section xAxisAggregation">
        <div className="title">
            {i18n.t("kpi.xAxisAggregation")}
            <Spotlight id={buildControllerSpotlightId(location.pathname, ["aggregation"])} className="mls" />
        </div>

        <div className="buttons-2col">
            <button id="button-aggregation-product" className={settings.kpi.aggregation === AggregationTypes.Product ? "active" : undefined} onClick={() => settings.setKpiState({ aggregation: AggregationTypes.Product })}>{i18n.t("common.products")}</button>
            <button id="button-aggregation-order" disabled={props.disableOrders} className={settings.kpi.aggregation === AggregationTypes.Case ? "active" : undefined} onClick={() => settings.setKpiState({ aggregation: AggregationTypes.Case })}>{i18n.t("common.cases")}</button>
            {props.showTime === true && <button id="button-aggregation-time" className={settings.kpi.aggregation === AggregationTypes.Time ? "active" : undefined} onClick={() => settings.setKpiState({ aggregation: AggregationTypes.Time })}>{i18n.t("time")}</button>}
        </div>

        {props.showTime === true && settings.kpi.aggregation === AggregationTypes.Time && <div className="buttons-2col mt">
            <label>{i18n.t("common.scale")}</label>
            <Dropdown
                options={timeScaleOptions}
                value={timeScaleOptions.find(o => o.value === settings.kpi.timeScale)!}
                onChange={(value) => settings.setKpiState({ timeScale: value!.value as TimePeriodFrequencies })}
            />
        </div>}
    </div>;
}

export function KpiSortOrderSwitch(props: { spotlightId?: string, dropdownOptions?: StringOption[] }) {

    const settings = useContext(SettingsContext);
    const location = useLocation();

    // If there is only one option to choose from, there is no point in displaying the selection
    const hasDropdown = props.dropdownOptions && props.dropdownOptions.length > 1;

    const hasComparison = settings.kpi.comparisons !== KpiComparisons.None;

    useEffect(() => {
        if (!props.dropdownOptions?.map(o => o.value).includes(settings.kpi.sortBy))
            settings.setKpiState({ sortBy: props.dropdownOptions![0].value as SortByType });
    }, [settings.kpi.sortBy, props.dropdownOptions]);

    return <div className="section sortOrder">
        <div className="title">
            {hasDropdown ? i18n.t("explorer.sortOrderBy") : i18n.t("explorer.sortOrder")}

            <Spotlight id={props.spotlightId ?? buildControllerSpotlightId(
                location.pathname,
                ["SortBy",
                    settings.kpi.aggregation.toString() + (hasComparison ? "" :
                        "-NoComparison")])}
            className="mls" />
        </div>

        {hasDropdown && props.dropdownOptions !== undefined && <KpiSortBy dropdownOptions={props.dropdownOptions} />}
        <SortOrderSwitch />
    </div>;
}

export function SortOrderSwitch() {

    const settings = useContext(SettingsContext);

    return <div className="buttons-2col mtLarge">
        <button id="button-ascending" className={settings.kpi.sortOrder === SortOrder.Ascending ? "active" : undefined} onClick={() => settings.setKpiState({ sortOrder: SortOrder.Ascending })}>{i18n.t("explorer.ascending")}</button>
        <button id="button-descending" className={settings.kpi.sortOrder === SortOrder.Descending ? "active" : undefined} onClick={() => settings.setKpiState({ sortOrder: SortOrder.Descending })}>{i18n.t("explorer.descending")}</button>
    </div>;
}

export function KpiSortBy(props: { dropdownOptions: StringOption[] }) {
    const settings = useContext(SettingsContext);
    const options = props.dropdownOptions;

    return <div className="sortBy">
        <Dropdown
            options={options}
            value={options.find(o => o.value === settings.kpi.sortBy) ?? options[0]}
            onChange={e => {
                settings.setKpiState({
                    sortBy: e?.value as SortByType
                });
            }}
        />
    </div>;
}

export function CaseKpiSortBy() {
    const sortOptions = [
        { label: i18n.t("kpi.sortBy.selectedKpi"), value: SortByType.Kpi },
        { label: i18n.t("kpi.deviationFromComparison"), value: SortByType.DeviationFromComparison },
    ];

    return <KpiSortOrderSwitch dropdownOptions={sortOptions} />;
}

export function getValidBenchmarkingKpiSortBySettings(session: SessionType, settings: SettingsType, props?: BenchmarkingKpiSortByProps): DeepPartial<SettingsType> {
    const sortOptions = getSortOptions(session, settings, session.project?.uploadIdPlan !== undefined, props?.isValueStream, props?.enableOrderSequencesSorting);
    if (!sortOptions.includes(settings.kpi.sortBy) && sortOptions.length > 0)
        return { kpi: { sortBy: sortOptions[0] }};

    return { };
}

type BenchmarkingKpiSortByProps = {
    spotlightId?: string;
    isValueStream?: boolean;
    enableOrderSequencesSorting?: boolean;
};

export function BenchmarkingKpiSortBy(props: BenchmarkingKpiSortByProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const sortOptions = getSortOptions(session, settings, session.project?.uploadIdPlan !== undefined, props.isValueStream, props.enableOrderSequencesSorting);
    const labelLookup = {
        [SortByType.Kpi]: i18n.t("kpi.sortBy.selectedKpi"),
        [SortByType.DeviationFromComparison]: i18n.t("kpi.deviationFromComparison"),
        [SortByType.Frequency]: props.isValueStream ? i18n.t("common.actionFrequency") : i18n.t("common.caseCount"),
        [SortByType.OrderSequences]: i18n.t("common.orderSequences"),
        [SortByType.Alphabetical]: i18n.t("common.alphabetical"),
        [SortByType.Percentiles]: i18n.t("common.statistics.fluctuation"),
        [SortByType.Median]: i18n.t("common.statistics.median"),
    };

    const dropdownOptions = sortOptions.map(o => { return { value: o, label: labelLookup[o] }; });
    if (dropdownOptions.length === 0)
        // Nothing to sort by. Don't show the sort order switch
        return null;

    return <KpiSortOrderSwitch spotlightId={props.spotlightId} dropdownOptions={dropdownOptions} />;
}

/**
 * Provides a list of sort options that make sense for a given combination of aggregation
 * and comparisons. Uses settings.kpi.aggregation, ...comparisons and ...statistic to do so.
 * @param projectHasPlanningData usually you want to set this to `session.project?.uploadIdPlan !== undefined`
 * @returns 
 */
export function getSortOptions(session: SessionType, settings: SettingsType, projectHasPlanningData: boolean, isValueStream?: boolean, enableOrderSequencesSorting?: boolean) {
    const result: SortByType[] = [];

    const aggregation = settings.kpi.aggregation;
    const comparisons = settings.kpi.comparisons;

    // For variance statistics we only support median and percentiles for now.
    if (settings.kpi.statistic === StatisticTypes.Variance)
        return [SortByType.Median, SortByType.Percentiles];

    // Sorting by selected KPI is useful for both product- and case aggregations or for value stream analysis
    const enableKpiSorting = aggregation !== AggregationTypes.Time || (aggregation === AggregationTypes.Time && isValueStream);
    if (enableKpiSorting)
        result.push(SortByType.Kpi);

    // Alphabetical sorting and sorting by case only make sense for products or for value stream analysis
    const enableDefaultSorting = aggregation === AggregationTypes.Product || isValueStream;
    if (enableDefaultSorting) {
        result.push(SortByType.Frequency);
        result.push(SortByType.Alphabetical);
    }

    // sorting by deviation is currently only supported comparisons with planning and best process data
    // for both product- and case aggregations and for value stream analysis.
    const enableDeviationSorting = ((comparisons === KpiComparisons.Planning && projectHasPlanningData) || (comparisons === KpiComparisons.BestProcesses && !hasProductCustomKpi(session, settings))) &&
        (aggregation !== AggregationTypes.Time || aggregation === AggregationTypes.Time && isValueStream);
    if (enableDeviationSorting)
        result.push(SortByType.DeviationFromComparison);

    //Show order sequences option for value stream analysis and only for activities
    if (isValueStream && enableOrderSequencesSorting) {
        result.push(SortByType.OrderSequences);
    }

    return result;
}

export function QuantityDropdown(props: {
    quantities: Quantity[];
    className?: string;
    hasSeparateSection?: boolean;    
    value?: BaseQuantityType;
    onChange?: (value: BaseQuantityType) => void;
}) {
    const settings = useContext(SettingsContext);

    const options = props.quantities.map(q => {
        return {
            label: i18n.t(q.name),
            value: q.id,
        };
    });

    if (options.length <= 1)
        return null;

    return <div className={`${props.hasSeparateSection ? "section" : "dropdownSection"} baseUnit mtLarge`}>
        <div className={props.hasSeparateSection ? "sectionTitle" : "label"}>{i18n.t("common.unit")}</div>

        <Dropdown
            className={props.className}
            options={options}
            testId="dropdown-quantity"
            value={options.find(o => o.value === (props.onChange ? props.value : settings.quantity)) ?? options[0]}
            onChange={e => {
                const quantity = e!.value as BaseQuantityType;

                if (props.onChange)
                    props.onChange(quantity);
                else
                    settings.set({
                        quantity,
                    });
            }}
        />
    </div>;
}
