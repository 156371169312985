import React, { Fragment, useContext } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, SettingsContextType, SettingsType } from "../../contexts/SettingsContext";
import { useMountedState } from "../../hooks/UseMounted";
import { useProductCaseAggregations } from "../../hooks/UseProductCaseAggregations";
import { useStatistics } from "../../hooks/UseStatistics";
import i18n from "../../i18n";
import { EventFilter, isFilterEqual } from "../../models/EventFilter";
import { Formatter } from "../../utils/Formatter";
import TabFilterEditor from "../filters/editors/TabFilterEditor";
import FilterStatus from "../filters/FilterStatus";
import { JsxTemplateString } from "../i18n-links/JsxTemplateString";
import { Spotlight } from "../spotlight/Spotlight";
import { ValueSpinner } from "../value-spinner/ValueSpinner";
import { useLocation } from "react-router-dom";
import { TimeMode, TimePeriodFrequencies } from "../../models/ApiTypes";
import { getFilterStartEndTimes } from "../../views/gantt/CommonGantt";
import { toUserTimezoneMillis } from "../../utils/TimezoneUtils";

export type FilterEditorProps = {
    onFiltersCommitted?: () => void;
    enableOnlyTimespan?: boolean;
}

/**
 * This is a default for the case time filter. It can be used if just the time filter should open up.
 */
export const defaultCaseTimeFilter: EventFilter = { caseTime: { requireStartInRange: true, requireEndInRange: true } };

const aggregationOptions = {
    limit: 0,
};

export default function FilterEditor(props: FilterEditorProps) {
    const isMounted = useMountedState();
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const location = useLocation();

    const [stats, isStatsLoading] = useStatistics(undefined);
    const [productCaseAggregations, isProductCaseAggregationsLoading] = useProductCaseAggregations(aggregationOptions, false);
    const [allProductCaseAggregations, isAllProductCaseAggregationsLoading] = useProductCaseAggregations({ ...aggregationOptions, eventFilters: [] }, false);

    const isLoading = isStatsLoading || isAllProductCaseAggregationsLoading || isProductCaseAggregationsLoading;

    const isGanttView = location.pathname.includes("gantt");
    const useTimeFilterRange = isGanttView && settings.gantt.restrictToTimeFilter && settings.gantt.timeMode === TimeMode.Absolute;
    const filterStartEndTimes = getFilterStartEndTimes(settings.previewFilters ?? settings.filters);
    const startDate = useTimeFilterRange && isGanttView && filterStartEndTimes?.minTime ? new Date(filterStartEndTimes?.minTime * 1000) : stats.minDate;
    const endDate = useTimeFilterRange && isGanttView && filterStartEndTimes?.maxTime ? new Date(filterStartEndTimes?.maxTime * 1000) : stats.maxDate;
    const numDays = startDate && endDate ? (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) : undefined;
    const timeFormatter = numDays !== undefined && numDays > 8 ?
        (date: Date | undefined) => Formatter.formatDate(date, undefined, session.locale, session.timezone) :
        (date: Date | undefined) => date ? Formatter.formatTime(TimePeriodFrequencies.Hour, toUserTimezoneMillis(date.getTime(), session.timezone), session.timezone) : "";

    const timespanTemplate = <JsxTemplateString
        key="caseCountTimespan"
        template={i18n.t("filters.timespan")}
        mapping={{
            minDate: <>{timeFormatter(startDate)}</>,
            maxDate: <>{timeFormatter(endDate)}</>,
        }}
    />;

    const infos: JSX.Element[] = props.enableOnlyTimespan ? [<Fragment key="caseCount">{i18n.t("common.allCases")}</Fragment>] : [<JsxTemplateString
        key="caseCount"
        template={i18n.t("filters.caseCount")}
        mapping={{
            numFilteredCases: <>{Formatter.formatNumber(stats.numFilteredTraces || 0, 0, session.numberFormatLocale)}</>,
            numTotalCases: <>{Formatter.formatNumber(stats.numTotalTraces || 0, 0, session.numberFormatLocale)}</>,
        }}
    />];

    const hasProducts = session.project?.eventKeys !== undefined && session.project?.eventKeys?.product !== undefined;

    if (hasProducts)
        if (props.enableOnlyTimespan)
            infos.push(<Fragment key="productCount"> | {i18n.t("common.allProducts")}</Fragment>);
        else
            infos.push(<Fragment key="productCount"> | <JsxTemplateString
                template={i18n.t("filters.productCount")}
                mapping={{
                    numFilteredProducts: <>{Formatter.formatNumber(productCaseAggregations?.log?.productCount, undefined, session.numberFormatLocale)}</>,
                    numTotalProducts: <>{Formatter.formatNumber(allProductCaseAggregations?.log?.productCount, undefined, session.numberFormatLocale)}</>,
                }}
            /></Fragment>);

    infos.push(<Fragment key="timespan"> | {timespanTemplate}
    </Fragment>);

    return <div className="filterContainer light">
        <div className="title">
            <span>{i18n.t("filters.title")}</span>

            <ValueSpinner isLoading={isLoading} classNameSpinning="outerSpinner">
                {stats.numFilteredTraces === 0 ? <span>{i18n.t("filters.noCasesSelected")}</span> : infos}
            </ValueSpinner>
        </div>

        <div className="filterStatus">
            {session.project &&
                <FilterStatus
                    numTotalCases={stats.numTotalTraces || 0}
                    numPassingCases={stats.numFilteredTraces || 0}
                    onAddButtonClicked={() => {
                        settings.set({
                            previewFilters: [...settings.filters],
                            filterEditor: {
                                showFilterEditor: true,
                                editFilterIndex: undefined,
                                // For only timespan we initialize a caseTime filter. In all other cases this should be undefined.
                                editFilter: props.enableOnlyTimespan ? defaultCaseTimeFilter : undefined
                            }
                        });
                    }}
                    onFilterRemoved={(filter, idx) => {
                        const newSettings: Partial<SettingsType> = {
                            filters: settings.filters.filter((f, i) => {
                                return i !== idx;
                            }),
                            previewFilters: settings.previewFilters?.filter((f, i) => {
                                return i !== idx;
                            }),
                            graph: {
                                ...settings.graph,
                                complexityCutoffScore: undefined,
                            }
                        };

                        if (settings.filterEditor.showFilterEditor) {
                            if (idx === settings.filterEditor.editFilterIndex) {
                                // Close editor in case we've just removed the filter we've been
                                // editing
                                newSettings.previewFilters = undefined;
                                newSettings.filterEditor = {
                                    showFilterEditor: false,
                                    editFilter: undefined,
                                    editFilterIndex: undefined,
                                };
                            } else {
                                // A filter in the chain has been removed, so we might
                                // have to update the index.
                                if (settings.filterEditor.editFilterIndex !== undefined &&
                                    idx < settings.filterEditor.editFilterIndex) {
                                    // We've removed a filter which sat before the one we're
                                    // editing. So it's index changed and we need to adapt
                                    newSettings.filterEditor = {
                                        ...settings.filterEditor,
                                        editFilterIndex: settings.filterEditor.editFilterIndex - 1,
                                    };
                                }
                            }
                        }

                        settings.set(newSettings);
                    }}
                    onFilterClicked={(filter, idx) => {
                        const edit = settings.filterEditor;
                        if (edit.editFilterIndex === idx && edit.showFilterEditor) {
                            // User is already editing this filter. Do nothing!
                            return;
                        }
                        settings.set({
                            previewFilters: [...settings.filters],
                            filterEditor: {
                                showFilterEditor: true,
                                editFilter: filter,
                                editFilterIndex: idx
                            }
                        });
                    }}
                    filters={settings.filters}
                />}

            {!settings.filterEditor.showFilterEditor && <Spotlight id="Filter-Closed" />}
        </div>

        {settings.filterEditor.showFilterEditor && <div className="tabFilterContainer" data-testid="tabFilterContainer">
            <TabFilterEditor
                initialValue={settings.filterEditor.editFilter}
                onChange={handleFilterUpdate}>
                <div className="addFilterButton">
                    <button onClick={addFilter} className="center shortcutButton" data-testid="commitFilterButton">
                        {i18n.t("filters.save")}
                    </button>
                    <button onClick={discardFilter} className="center shortcutButton">
                        {i18n.t("filters.discard")}
                    </button>
                </div>
            </TabFilterEditor>
        </div>}
    </div>;

    function discardFilter() {
        settings.set({
            previewFilters: undefined,
            filterEditor: {
                showFilterEditor: false,
                editFilter: undefined,
                editFilterIndex: undefined,
            }
        });
    }

    function addFilter() {
        settings.set({
            filters: settings.previewFilters,
            previewFilters: undefined,
            filterEditor: {
                showFilterEditor: false,
                editFilter: undefined,
                editFilterIndex: undefined,
            }
        });

        if (props.onFiltersCommitted)
            props.onFiltersCommitted();
    }

    /**
     * Called by filter editors when the user made a change on the current filter
     */
    function handleFilterUpdate(filter: EventFilter | undefined) {
        const newState: Partial<SettingsContextType> = {
            graph: {
                ...settings.graph,
                complexityCutoffScore: undefined,
            },
            previewFilters: getPreviewFilters(filter),
        };

        if (isMounted())
            settings.set(newState);
    }

    function getPreviewFilters(filter: EventFilter | undefined): EventFilter[] {
        // This helper makes the provided filter unique in the filters array. One
        // appearence is ok, but the second will be removed
        const makeFilterUnique = (filters: EventFilter[], filter: EventFilter) => {
            const result: EventFilter[] = [];
            let iveSeenThisFilterBefore = false;
            for (let i = 0; i < filters.length; i++) {
                const isEqual = isFilterEqual(filters[i], filter);
                if (!isEqual || !iveSeenThisFilterBefore)
                    result.push(filters[i]);

                iveSeenThisFilterBefore = iveSeenThisFilterBefore || isEqual;
            }

            return result;
        };


        // Filter may have settings that make it not filtering anything. In that
        // case, it is better to not add a filter at all.
        if (filter === undefined || filter.filters?.length === 0) {
            let result: EventFilter[] = [];
            if (settings.filterEditor.editFilterIndex !== undefined)
                result = (settings.filters || []).filter((f, idx) => {
                    return settings.filterEditor.editFilterIndex !== idx;
                });
            else
                result = settings.filters;

            return filter === undefined ? result : makeFilterUnique(result, filter);
        }

        // We have a filter index, so let's replace the indexed filter
        // with what we got in the parameters
        if (settings.filterEditor.editFilterIndex !== undefined) {
            const result = (settings.filters || []).map((f, idx) => {
                return settings.filterEditor.editFilterIndex === idx ? filter! : f;
            });

            result[settings.filterEditor.editFilterIndex] = filter;

            return makeFilterUnique(result, filter);
        }

        // Append new filter at the end of the filter list
        return makeFilterUnique(settings.filters.concat([filter!]), filter);
    }
}
