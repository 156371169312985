import ELK from "elkjs";
import { Worker } from "elkjs/lib/elk-worker";
import { ElkExtendedEdge, ElkNode, ElkPort } from "elkjs";
import { SessionType } from "../../contexts/SessionContext";
import { GraphOrientation, SettingsType } from "../../contexts/SettingsContext";
import { BaseGraph, GroupingKeys, Layout, MultiEdge, Node, NodeActivitySchema, NodeRoles, RenderNodeGroupOptions, RenderNodeOptions } from "../../models/Dfg";
import { Vector } from "../../utils/Vector";
import { allPossibleSecondGroups, possibleSecondGroupsMap } from "../controls/GroupingKeyControls";
import { ChartEdge } from "./ChartEdge";
import { EdgeLabelFunc, MarkupFunc } from "./DfGraph";
import { Layouter } from "./Layouter";
import { SimpleCache } from "../../utils/SimpleCache";
import { getMetrics } from "./nodes/NodeRenderer";
import Global from "../../Global";
import { isTerminalNode, isTerminalNodeId } from "../../utils/DfgUtils";
import { KpiTypes } from "../../models/KpiTypes";
import i18n from "../../i18n";

export type LayoutOptions = {
    /** Distance that outgoing/incoming edges need to go straight after/before leaving/entering node. */
    portOffset: number,
    /** Which edge routing algorithm to use: https://www.eclipse.org/elk/reference/options/org-eclipse-elk-edgeRouting.html */
    edgeRouting: "POLYLINE" | "ORTHOGONAL",
    /** How much spacing is between graph components. */
    spacing: number,
    /** Strategy for cycle breaking: https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-cycleBreaking-strategy.html */
    cycleBreakingStrategy: "DEPTH_FIRST" | "GREEDY",
    /** If set, start/end nodes are placed before/after all other nodes in the layout */
    partitionTerminalNodes: boolean,
    /** how hard to try to find a better solution: https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-thoroughness.html */
    thoroughness: number
    /** node placement strategy.  The most complex and nicest ist NETWORK_SIMPLEX, but it has problems with huge graphs: https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-nodePlacement-strategy.html */
    nodePlacementStrategy: "NETWORK_SIMPLEX" | "BRANDES_KOEPF" | "SIMPLE"
    /** layering strategy for adding layers (graph algorithm speak). In a horizontal layout this is basically how far right the nodes get placed: https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-layering-strategy.html
     * Additional docstrings regarding the options are here: https://github.com/eclipse/elk/blob/master/plugins/org.eclipse.elk.alg.layered/src/org/eclipse/elk/alg/layered/options/LayeringStrategy.java
    */
    layeringStrategy: "NETWORK_SIMPLEX" | "LONGEST_PATH"
}

const defaultLayoutOptions: LayoutOptions = {
    portOffset: 25,
    /**
     * By default, to make sure that we continue forward as long as we can, we use
     * the "depth first" cycle breaking strategy.
     * This makes graphs much "longer" and makes loopback edges much
     * longer, but allows the graph to continue along the normal flow
     * for as long as possible
     */
    cycleBreakingStrategy: "DEPTH_FIRST",
    edgeRouting: "POLYLINE",
    /** ELK default is ~20, we want a bit more air */
    spacing: 30,
    /** Set by default so that start/end nodes can be clearly identified. */
    partitionTerminalNodes: true,
    thoroughness: 15,
    nodePlacementStrategy: "BRANDES_KOEPF",
    layeringStrategy: "NETWORK_SIMPLEX"
};

// A few convenience options for selecting options depending on our needs.

// For best results we use network simplex and a
export const bestResultLayoutOptions: Partial<LayoutOptions> = {
    nodePlacementStrategy: "NETWORK_SIMPLEX",
    layeringStrategy: "NETWORK_SIMPLEX",
    thoroughness: 20,
};

export const bestPerformanceLayoutOptions: Partial<LayoutOptions> = {
    nodePlacementStrategy: "BRANDES_KOEPF",
    layeringStrategy: "LONGEST_PATH",
    thoroughness: 4,
};


const elk = Global.isRunningJestTest ? new ELK() : new ELK({
    algorithms: ["layered"],
    workerFactory: function(url: any) { // the value of 'url' is irrelevant here
        return new Worker(Global.isRunningJestTest ? url as any : undefined);
    }
});

type LayoutNode = {node: Node, elkNode: ElkNode, group?: NodeGroup}
type LayoutGroup = {group: NodeGroup, elkNode: ElkNode}
type LayoutEdge = {multiEdge: MultiEdge, elkEdge: ElkExtendedEdge, group?: NodeGroup, isFromNode: boolean, isToNode: boolean}
type NodeGroup = {
    id: string,
    level: GroupingKeys,
    value: string
}

/** Calculate a layout using the ELKJS layout engine
 *
 * We use the layered ELKJS layouter: https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html
 */
export async function layoutGraph(graph: BaseGraph, settings: SettingsType, session: SessionType, markupFunc: MarkupFunc, edgeLabelFunc: EdgeLabelFunc | undefined, options?: Partial<LayoutOptions>) {
    const sideIn = settings.graph.orientation === GraphOrientation.horizontal ? "WEST" : "NORTH";
    const sideOut = settings.graph.orientation === GraphOrientation.horizontal ? "EAST" : "SOUTH";
    const sideSelfLoop = settings.graph.orientation === GraphOrientation.horizontal ? "SOUTH" : "EAST";

    const layoutOptions: LayoutOptions = {...defaultLayoutOptions, ...(options ?? {})};

    const portVector = settings.graph.orientation === GraphOrientation.horizontal ? new Vector(layoutOptions.portOffset, 0) : new Vector(0, layoutOptions.portOffset);

    const defaultNodeLayoutOptions = {
        "elk.layered.spacing.baseValue": layoutOptions.spacing.toString(),
        "org.eclipse.elk.spacing.nodeNode": (layoutOptions.spacing * 2).toString(),
        "elk.edgeRouting": layoutOptions.edgeRouting
    };

    // Ports are the entry and exit points of a node (elk needs us to define these explicitly)
    // In order to not create duplicate ports we assign deterministic ids to them and store them in a set.
    const portIds = new Set<string>();
    const addPortMaybe = (port: ElkPort, node: ElkNode) => {
        if (!portIds.has(port.id)) {
            if (!node.ports)
                node.ports = [];
            node.ports.push(port);
        }
    };

    // Create elk nodes and groups.
    // To make sure we don't create duplicate nodes and groups, we use
    // deterministic ids and assign them to maps.
    const layoutNodeMap = new Map<string, LayoutNode>();
    const layoutGroupMap = new Map<string, LayoutGroup>();
    for (const node of graph.nodes) {
        const nodeSize = getNodeMetrics(node, settings, session, markupFunc);
        const elkNode = {
            id: node.id,
            ports: [],
            children: [],
            layoutOptions: {
                ...defaultNodeLayoutOptions,
                portConstraints: "FIXED_SIDE",
                "elk.partitioning.partition": ({[NodeRoles.CarbonScope3]: "0", [NodeRoles.Start]: "1", [NodeRoles.End]: "3"} as any)[node.role ?? ""] ?? "2",
            },
            ...nodeSize
        };
        const group = getGroup(node, settings.groupingKey, settings.graph.secondGroupingLevel);
        layoutNodeMap.set(node.id, {node, elkNode, group});

        if (group) {
            let labelSize = measureEdgeText(group.value);
            if (settings.graph.orientation === GraphOrientation.vertical)
                // transpose size so that placement is correct
                labelSize = {height: labelSize.width, width: labelSize.height};
            layoutGroupMap.set(group.id, {group, elkNode: {
                id: group.id,
                children: [],
                ports: [],
                labels: group.value ? [{
                    text: group.value,
                    ...labelSize  // this isn't an edge but close enough
                }] : [],
                layoutOptions: {
                    ...defaultNodeLayoutOptions,
                    "nodeLabels.placement":  settings.graph.orientation === GraphOrientation.horizontal ? "INSIDE V_TOP H_CENTER" : "INSIDE V_CENTER H_LEFT",
                    "elk.partitioning.partition": "2",
                    "org.eclipse.elk.layered.nodePlacement.networkSimplex.nodeFlexibility": "NODE_SIZE"
                },
                edges: []
            }});
        }
    }

    // Build a map from "edge id" to "layout edge" and add ports to the source
    // and target nodes of each edge.
    const layoutEdgeMap = new Map<string, LayoutEdge>();
    for (const multiEdge of graph.multiEdges ?? []) {
        const id = `${multiEdge.from} -> ${multiEdge.to}`;
        const edgeLabelText = placeholderEdgeLabel((edgeLabelFunc ? edgeLabelFunc(multiEdge, settings, session) : undefined) ?? "", "A_____A", multiEdge, settings);
        const edgeLabelSize = measureEdgeText(edgeLabelText);

        const sourceNode = layoutNodeMap.get(multiEdge.from);
        const targetNode = layoutNodeMap.get(multiEdge.to);
        if (sourceNode === undefined || targetNode == undefined)
            continue;
        const isSelfLoop = sourceNode.node.id === targetNode.node.id;
        const selfLoopPortIndicator = "selfLoop:";
        // An edge is contained inside a group if both source and target are in
        // the same group.
        const group = sourceNode?.group?.id !== undefined && sourceNode.group.id === targetNode?.group?.id ? sourceNode.group : undefined;

        // Each node should have a separate port for each corresponding source/target.
        // Overlapping edges however should not create multiple ports but used the same port.
        // Self loops have their own dedicated ports.
        const sourceNodePortId = `port:source:${isSelfLoop ? selfLoopPortIndicator : ""}${id}`;
        addPortMaybe({
            id: sourceNodePortId,
            layoutOptions: {side: isSelfLoop ? sideSelfLoop : sideOut, borderOffset: (isSelfLoop ? 0 : layoutOptions.portOffset).toString()}
        }, layoutNodeMap.get(sourceNode.node.id)!.elkNode);

        const targetNodePortId = `port:target:${isSelfLoop ? selfLoopPortIndicator : ""}${id}`;
        addPortMaybe({
            id: targetNodePortId,
            layoutOptions: {side: isSelfLoop ? sideSelfLoop : sideIn, borderOffset: (isSelfLoop ? 0 : layoutOptions.portOffset).toString()}
        }, layoutNodeMap.get(targetNode.node.id)!.elkNode);

        layoutEdgeMap.set(id, {
            multiEdge,
            group,
            elkEdge: {
                id: id,
                sources: [sourceNodePortId],
                targets: [targetNodePortId],
                labels: edgeLabelText ? [{
                    text: edgeLabelText,
                    ...edgeLabelSize
                }]: [],
                layoutOptions: {"priority.direction": "3"}
            },
            isFromNode: true,
            isToNode: true,
        });
    }

    // Finally build a hierarchical map based on nodes and their groups: In elk
    // both nodes and groups are handled the same, as nested "children" so we
    // have to place our actual nodes as children of groups if the node has a
    // group and as top level children if the node does not have a group.
    const elkNodeMap = new Map<string, ElkNode>();
    for (const {elkNode, group} of  layoutGroupMap.values()) {
        elkNodeMap.set(group.id, elkNode);
    }
    for (const {elkNode, group} of layoutNodeMap.values()) {
        if (group) {
            // Add a group node (if necessary) and place the current node into that group node
            const groupNode = elkNodeMap.get(group.id);
            groupNode?.children?.push(elkNode);
        }
        else {
            // No higher group found so just add the current node to the top level
            elkNodeMap.set(elkNode.id, elkNode);
        }
    }
    const topLevelElkNodes = Array.from(elkNodeMap.values());

    // Add edges to groups and place all other edges in top level
    const topLevelelkEdges: ElkExtendedEdge[] = [];
    for (const {elkEdge, group} of layoutEdgeMap.values()) {
        const groupNode = group?.id !== undefined ? elkNodeMap.get(group?.id) : undefined;
        if (groupNode) {
            if (!groupNode.edges)
                groupNode.edges = [];
            groupNode.edges.push(elkEdge);
        } else {
            topLevelelkEdges.push(elkEdge);
        }
    }


    const elkGraph: ElkNode = {
        id: "root",
        layoutOptions: {
            "elk.algorithm": "org.eclipse.elk.layered",
            "elk.direction": settings.graph.orientation === GraphOrientation.horizontal ? "RIGHT" : "DOWN",
            // Important to allow edges to be routed through groups, otherwise
            // edges can only be routed within the same group.
            "elk.hierarchyHandling": "INCLUDE_CHILDREN",
            "elk.spacing.edgeLabel": "1",
            // Make sure that edges always enter the "front" of the node.  This
            // makes the direction of flow clearer.
            "elk.partitioning.activate": layoutOptions.partitionTerminalNodes.toString(),
            "elk.layered.cycleBreaking.strategy": layoutOptions.cycleBreakingStrategy,
            "org.eclipse.elk.layered.nodePlacement.favorStraightEdges": "true",
            "org.eclipse.elk.layered.mergeHierarchyEdges": "false",
            "org.eclipse.elk.layered.edgeRouting.polyline.slopedEdgeZoneWidth": "4",
            "org.eclipse.elk.layered.layering.strategy": layoutOptions.layeringStrategy,
            "org.eclipse.elk.layered.nodePlacement.strategy": layoutOptions.nodePlacementStrategy,
            "org.eclipse.elk.layered.thoroughness": layoutOptions.thoroughness.toString(),
            ...defaultNodeLayoutOptions,
        },
        children: topLevelElkNodes,
        edges: topLevelelkEdges,
    };

    const layout = await elk.layout(elkGraph);

    // Create result layout instance
    const result: Layout = {
        nodes: toRenderNodes(layout),
        groups: toRenderGroups(layout),
        edges: toChartEdges(layout, layoutEdgeMap, portVector),
        hash: ""
    };

    return result;
}

const getGroup = (node: Node, groupingKey: GroupingKeys, secondGroupingLevel: GroupingKeys): NodeGroup | undefined => {
    if (isTerminalNode(node))
        return;
    // We don't want to group by pass value stream for inventory nodes.
    if (secondGroupingLevel === GroupingKeys.PassValueStream && node.role === NodeRoles.Inventory)
        return;
    if (possibleSecondGroupsMap.find(v => v.groupingKey === groupingKey) !== undefined) {
        const activityValueKey = groupingKeyToActivityValueKey(secondGroupingLevel);
        if (activityValueKey === undefined)
            return;
        const secondGroupSetting = allPossibleSecondGroups.find(v => v.value === secondGroupingLevel);
        const nUnique = node.activityValues?.[activityValueKey]?.nUnique ?? undefined;
        if (nUnique !== undefined && nUnique > 1 && secondGroupSetting?.nonUniqueLabel !== undefined)
            return {
                id: `group:${secondGroupingLevel}:nonUnique`,
                level: secondGroupingLevel,
                value: i18n.t(secondGroupSetting?.nonUniqueLabel)
            };
        const groupValue = node.activityValues?.[activityValueKey]?.value;
        if (groupValue === undefined)
            return;
        return {
            id: `group:${secondGroupingLevel}:${groupValue}`,
            level: secondGroupingLevel,
            value: secondGroupSetting?.addLabelAsPrefix ? `${i18n.t(secondGroupSetting.label)} ${groupValue}` : groupValue
        };
    }
    return undefined;
};

function groupingKeyToActivityValueKey(g: GroupingKeys) {
    const activityValueKey = {
        [GroupingKeys.None]: undefined,
        [GroupingKeys.Machine]: "machine",
        [GroupingKeys.MachineType]: "machineType",
        [GroupingKeys.Location]: "location",
        [GroupingKeys.NoneValueStream]: undefined,
        [GroupingKeys.MachineValueStream]: undefined,
        [GroupingKeys.MachineTypeValueStream]: undefined,
        [GroupingKeys.LocationValueStream]: undefined,
        [GroupingKeys.PassValueStream]: "passId",
        [GroupingKeys.ObjectType]: "objectType",
        [GroupingKeys.MachineObjectType]: undefined,
        [GroupingKeys.ObjectTypeValueStream]: undefined,
        [GroupingKeys.MachineObjectTypeValueStream]: undefined,
        [GroupingKeys.Product]: "product",
    }[g] as (keyof NodeActivitySchema) | undefined;
    return activityValueKey;
}


function toRenderPositionOptions(elkNode: ElkNode, parentElkNode?: ElkNode) {
    const zeroPoint = parentElkNode ? {x: parentElkNode.x ?? 0, y: parentElkNode.y ?? 0} : {x: 0, y: 0};
    return {
        position: {
            x: elkNode.x! + zeroPoint.x,
            y: elkNode.y! + zeroPoint.y,
        },
        size: {
            width: elkNode.width!,
            height: elkNode.height!,
        },
    };
}

function toRenderGroups(elkNode: ElkNode, parentElkNode?: ElkNode) {
    const result: {[id: string]: RenderNodeGroupOptions} = {};
    if (elkNode.children?.length) {
        // for groups we are checking whether all children have ports/connections to avoid empty groups flying around
        // TODO: Not sure if we still need the last check (some edges going in or out of some node inside the group).
        if (parentElkNode && elkNode.id.startsWith("group:") && elkNode.children.length && elkNode.children.some(c => c.ports?.length))
            result[elkNode.id] = {
                ...toRenderPositionOptions(elkNode, parentElkNode),
                label: elkNode?.labels?.length ? elkNode.labels[0]?.text : undefined
            };

        for (const subNode of elkNode.children)
            Object.assign(result, toRenderGroups(subNode, elkNode));
    }
    return result;
}

function toRenderNodes(elkNode: ElkNode) {
    const result: {[id: string]: RenderNodeOptions} = {};
    for (const subNode of elkNode.children ?? []) {
        result[subNode.id] = toRenderPositionOptions(subNode, elkNode);
        Object.assign(result, toRenderNodes(subNode));
    }
    return result;
}

function toChartEdges(elkNode: ElkNode, layoutEdgeMap: Map<string, LayoutEdge>, portVector: Vector) {
    const result: ChartEdge[] = [];
    // Copy over edge points
    for (const elkEdge of (elkNode.edges ?? [])) {
        const id = elkEdge.id;
        const layoutEdge = layoutEdgeMap.get(id);
        if (!layoutEdge)
            continue;
        const section = (elkEdge.sections || [])[0];
        if (section === undefined)
            continue;

        const isSelfLoop = layoutEdge.multiEdge.from === layoutEdge.multiEdge.to;
        // get base coordinate system of parent node
        const zeroPoint = new Vector(elkNode.x ?? 0, elkNode.y ?? 0);

        const points = [
            ...(layoutEdge.isFromNode && !isSelfLoop ? [Vector.fromPoint(section.startPoint).subtract(portVector)] : []),
            section.startPoint,
            ...(section.bendPoints || []),
            section.endPoint,
            ...(layoutEdge.isToNode && !isSelfLoop ? [Vector.fromPoint(section.endPoint).add(portVector)] : []),
        ].map(p => zeroPoint.add(Vector.fromPoint(p)));

        const edgeLabel = (elkEdge.labels || [])[0];
        const labelLocation = edgeLabel ? new Vector(edgeLabel.x!, edgeLabel.y!).add(zeroPoint) : undefined;

        result.push(new ChartEdge(
            id,
            {
                ...layoutEdge.multiEdge,
                renderOptions: {
                    points,
                    labelLocation
                }
            },
            layoutEdge.isToNode // add arrow when target is node
        ));
    }

    for (const subNode of elkNode.children ?? []) {
        result.push(...toChartEdges(subNode, layoutEdgeMap, portVector));
    }

    return result;
}


/**
 * Measures the node's metrics.
 * @param node Node to measure
 * @param detailLevel Desired node detail level
 * @param grouping Grouping used
 * @returns Width and height of the node
 */
function getNodeMetrics(node: Node, settings: SettingsType, session: SessionType, markupFunc: MarkupFunc) {
    if (node.role === NodeRoles.Start || node.role === NodeRoles.End)
        return { width: 60, height: 60 };
    if (node.role === NodeRoles.Link)
        return { width: 24, height: 24 };

    const markup = markupFunc(node, settings, session);
    const nodeMetrics = getMetrics(markup);

    if (node.role === NodeRoles.Inventory)
        // Add some space for the inventory icon
        nodeMetrics.height += 46;

    return nodeMetrics;
}

const edgeLabelSizeCache = new SimpleCache<string, { width: number, height: number }>(10000);

function measureEdgeText(text: string, container = "edgeMeasureContainer") {
    const key = container + "/" + text;
    const c = edgeLabelSizeCache;
    return c.get(key) ?? c.set(key, Layouter.measureFontSize(container, text)).get(key)!;
}

/**
 * Fallback edge label in the layout phase can be used to reserve space for an edge label that might not actually be there.
 * This is useful when layouts should not change even though edge labels are added or removed.
 *
 * Currently, this function hard codes the functionality that in object centric
 * graphs, labels are assumed on almost all edges, allowing the layouter to
 * switch between object types without drastically changing the layout.
 */
function placeholderEdgeLabel(edgeLabel: string, placeholderString: string, multiEdge: MultiEdge, settings: SettingsType) {
    if (edgeLabel && edgeLabel.trim())
        return edgeLabel;

    // Note that this isn't pretty but we assume that all edges to terminal
    // nodes when we are not analyzing frequencies will not have labels
    const isTerminalEdge = isTerminalNodeId(multiEdge.from);
    const isPlaceholderText = (!isTerminalEdge || settings?.kpi.selectedKpi === KpiTypes.Frequency);
    // A simple underscore is a nice string to reserve some small space for a placeholder label.
    return isPlaceholderText ? placeholderString : "";
}
