import React from "react";
import i18n from "../../i18n";
import { Project } from "../../models/Project";
import { classNames } from "../../utils/Utils";
import { Avatar } from "../avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { SessionContextType } from "../../contexts/SessionContext";
import { useProjectsList } from "../../hooks/useProjectsList";

export type NavProjectsDisplayProps = {
    session: SessionContextType;
    handleProjectSelection: (project?: Project) => void;
};

export function NavProjectsDisplay({ session, handleProjectSelection }: NavProjectsDisplayProps) {
    const navigate = useNavigate();
    const project = session?.project;
    const [projects] = useProjectsList();

    return <div className="navProjectsDisplay">
        <div
            className="allProjects"
            onClick={() => {
                handleProjectSelection(undefined);
                navigate("/projects/");
            }}>
            <svg className="svg-icon xsmall">
                <use xlinkHref="#radix-home" />
            </svg>
            <span>{i18n.t("common.allProjects")}</span>
        </div>
        <div className="projectsListContainer">
            {<ul className="projectList">
                {!!projects?.length && projects.map((p) =>
                    <li key={p.id}
                        className={classNames(["projectItem", p.id === project?.id && "projectItem--selected"])}
                        onClick={() => handleProjectSelection(p.project)}>
                        <Avatar name={p.project.name} shadow={false} />
                        <span className="oneRow">{p.project.name}</span>
                    </li>
                )}
            </ul>}
        </div>
    </div>;
}
