
export enum ContentType {
    Text,
    Html,
}

/**
 * Holds layout utilities for measuring text elements
 */
export class Layouter {
    private static canvas = document.createElement("canvas");

    public static measureFontWidth(text: string, font: string) {
        const context = Layouter.canvas.getContext("2d");
        context!.font = font;
        const metrics = context!.measureText(text);
        return metrics.width;
    }

    public static measureFontSize(cssClass: string, element: string | JSX.Element, contentType: ContentType = ContentType.Text) {
        const div = document.createElement("div");
        div.className = cssClass;
        div.style.position = "absolute";
        div.style.visibility = "hidden";

        switch (contentType) {
            case ContentType.Text:
                div.innerText = element.toString();
                break;
            case ContentType.Html:
                div.innerHTML = element.toString();
                break;
        }

        document.body.appendChild(div);
        const rect = div.getBoundingClientRect();

        const result = {
            width: rect.width,
            height: rect.height,
        };

        document.body.removeChild(div);

        return result;
    }
}

/**
 * This function takes a string and returns a sliced version of it where each slice does not exceed a length of
 * maxWidth pixels
 * @param text the string we want to slice
 * @param maxWidth maximum width
 * @returns sliced substring array
 */
export function breakString(text: string, maxWidth: number, splitChars = [" ", "-", "_"], font: string) {
    const cacheKey = text + "_" + maxWidth + splitChars.join("-");
    if (Storage.breaks[cacheKey] !== undefined)
        return Storage.breaks[cacheKey];

    let rest = text;
    const result: string[] = [];

    while (rest.length > 0) {
        const splitOrder: number[] = Array(rest.length);

        splitOrder[rest.length - 1] = 1;

        let next = 2;
        for (const splitChar of splitChars)
            for (let i = rest.length - 1; i >= rest.length * 0.2; i--) {
                if (rest[i] === splitChar && splitOrder[i] === undefined) {
                    splitOrder[i] = next;
                    next++;
                }
            }

        for (let i = rest.length - 1; i >= 0; i--)
            if (splitOrder[i] === undefined) {
                splitOrder[i] = next;
                next++;
            }

        // Now find the best split that is shorter than maxWidth
        for (let i = 1; i <= splitOrder.length; i++) {
            const idx = splitOrder.findIndex(o => o === i);
            splitOrder[idx] = -1;
            const part = rest.substring(0, idx + 1).trim();

            const width = Layouter.measureFontWidth(part, font);
            if (width <= maxWidth) {
                // cool
                result.push(part);
                rest = rest.substring(idx + 1).trim();
                break;
            }
        }
    }

    Storage.breaks[cacheKey] = result;
    return result;
}

class Storage {
    static breaks: { [key: string]: string[] } = {};
}
