import { isString } from "lodash";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import i18n from "../../i18n";
import { isRouteActive, NavItem, NavItemProps } from "./NavItem";
import { classNames } from "../../utils/Utils";
import Global from "../../Global";

export type NavGroupProps = {
    title?: string;
    isSelected?: boolean;
    childElements: (NavItemProps | NavGroupProps)[];
    level?: number;
    icon?: string;
    className?: string;
    isCollapsible?: boolean;
    id?: string;
    markup?: JSX.Element;
    idSlug?: string;
    onCollapseExpand?: (isExpanded: boolean) => void;
    isExpanded?: boolean;
};

export function NavGroup(props: NavGroupProps) {
    const isCollapsible = props.isCollapsible === undefined || props.isCollapsible;
    const [collapseDelay, setCollapseDelay] = useState<NodeJS.Timeout | undefined>(undefined);
    const location = useLocation();
    const level = props.level ?? -1;

    const isGroupWithChildren = hasElements(props);

    const children = (props.childElements ?? []).map((c, idx) => {
        const active = someChild(c, (child) => {
            const url = isString(child.targetUrl) ? child.targetUrl : child.targetUrl?.();
            return isRouteActive(location.pathname, url, child);
        });

        const isGroup = isGroupProps(c);
        if (isGroup) {
            const key = c.title ?? idx.toString();
            return <NavGroup
                {...c as NavGroupProps}
                id={(props.id ? props.id + "." : "") + (c.idSlug ?? c.id ?? "group-" + idx)}
                key={key}
                onCollapseExpand={props.onCollapseExpand}
                isExpanded={props.isExpanded}
                isSelected={active}
                level={level + 1}
            />;
        }

        const childProps = c as NavItemProps;
        const key = (c.title ?? isString(childProps.targetUrl) ? childProps.targetUrl as string : childProps.targetUrl?.()) ?? `item-${idx}`;
        return <NavItem
            key={key}
            onClick={() => {
                if (props.onCollapseExpand) {
                    props.onCollapseExpand(false);
                }
            }}
            {...c as NavItemProps}
            id={(props.id ? props.id + "." : "") + (c.idSlug ?? "")}
            level={level + 1}
        />;
    });

    function handleMouseEnter() {
        if (Global.isTouchEnabled)
            return;

        const delay = setTimeout(() => {
            if (props.onCollapseExpand) {
                props.onCollapseExpand(true);
            }
        }, 300);

        setCollapseDelay(delay);

    }

    function handleMouseLeave() {
        if (Global.isTouchEnabled)
            return;

        if (collapseDelay !== undefined)
            clearTimeout(collapseDelay);
        if (props.onCollapseExpand) {
            props.onCollapseExpand(false);
        }
    }

    function handleTap() {
        if (!props.onCollapseExpand)
            return;

        props.onCollapseExpand(!props.isExpanded);
    }

    if (!isGroupWithChildren)
        return <></>;

    return <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classNames(["navGroup", props.isSelected && "selected", !props.isExpanded && isCollapsible && "collapsed", !isCollapsible && "navGroupNotCollapsible", props.className])}>
        {(props.title !== undefined || props.markup !== undefined) && <div
            className="title navItem"
            title={i18n.t(props?.title ?? "").toString()}
            id={props.id}
        >
            <div
                onClick={handleTap}
                className={classNames(["navGroupLevel" + level.toString(), "navItemLevel" + level.toString()])}>
                {props.markup !== undefined && props.markup}
                {props.markup === undefined && props.icon !== undefined && <div className="center navIconContainer"><svg className="svg-icon xsmall"><use xlinkHref={props.icon} /></svg></div>}
                <span className="oneRow">{props.markup === undefined && i18n.t(props.title ?? "")}</span>
            </div>
        </div>}
        <div className={isCollapsible ? "children" : "items"}>
            {children}
        </div>
    </div>;
}

export function hasElements(props: NavGroupProps | NavItemProps): boolean {
    if (isGroupProps(props))
        return (props as NavGroupProps).childElements?.some(c => hasElements(c));

    const itemProps = props as NavItemProps;
    return itemProps.isVisible === undefined || itemProps.isVisible;
}

export function someChild(props: NavGroupProps | NavItemProps, evaluator: (child: NavItemProps) => boolean): boolean {
    const isGroup = isGroupProps(props);
    if (isGroup) {
        const groupProps = props as NavGroupProps;
        return (groupProps.childElements ?? []).some(c => {
            return someChild(c, evaluator);
        });
    }
    return evaluator(props as NavItemProps);
}

function isGroupProps(props: NavGroupProps | NavItemProps | undefined) {
    return props !== undefined &&
        (props as NavItemProps).targetUrl === undefined &&
        (props as NavItemProps).onClick === undefined;
}