import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { capitalize, flatMap, get, isBoolean, noop, uniqBy } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactSwitch from "react-switch";
import Global from "../../Global";
import colors from "../../colors.json";
import { DashboardTile } from "../../components/dashboard-tile/DashboardTile";
import FilterEditor from "../../components/filter-editor/FilterEditor";
import Menu from "../../components/menu/Menu";
import { IModal } from "../../components/modal/Modal";
import { NoDataAvailable } from "../../components/no-data-available/NoDataAvailable";
import { ValueSpinner } from "../../components/value-spinner/ValueSpinner";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { DashboadTileSettings, DashboardSettingsType, SettingsContext, SettingsContextType, SettingsType } from "../../contexts/SettingsContext";
import { useDeviationTimeperiodStatistics } from "../../hooks/UseDeviationTimeperiodStatistics";
import { toGridLayout, useGridLayout } from "../../hooks/UseGridLayout";
import { useStatistics } from "../../hooks/UseStatistics";
import { AggregatedTimeperiodElementSchema, AggregatedTimeperiodSchema, useTimeAggregatedCaseStatistics } from "../../hooks/UseTimeAggregatedCaseStatistics";
import i18n from "../../i18n";
import { CustomKpi, StatsCalculationRequest, ViewConfigurationType, disableAllCalcOptions } from "../../models/ApiTypes";
import { GroupingKeys, Point } from "../../models/Dfg";
import { KpiDefinition, TimeperiodApis, decideTimeperiodApi, getFiltersForKpi, getKpiDefinition, getProductStatisticPath, getTimeperiodStatisticPath, getUnit } from "../../models/Kpi";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { buildTimeFilter } from "../../utils/FilterBuilder";
import { Formatter, UnitMetadata, getLongUnit } from "../../utils/Formatter";
import { ShareModal, shareAsync } from "../../utils/Share";
import { isInRange, timestampSort, toUserTimezone, toUserTimezoneMillis } from "../../utils/TimezoneUtils";
import updateUserpilotUrl from "../../utils/Userpilot";
import { classNames, getHash, isNiceNumber } from "../../utils/Utils";
import { EditFavoritesModal } from "../favorites/EditFavoritesModal";
import { DashboardTileSettingsModal, getContextFromTile, getContextOverride } from "./DashboardTileSettingsModal";
import { getPlanningState } from "../../utils/SettingsUtils";
import Spinner from "../../components/spinner/Spinner";
import { useTimeAggregatedEventStatistics } from "../../hooks/UseTimeAggregatedEventStatistics";
import { Api } from "../../api/Api";
import { quickFilter, QuickFilterDefinition, QuickFilterTypes } from "../../utils/QuickFilter";
import { getFilterStartEndTimes } from "../gantt/CommonGantt";
import { Spotlight } from "../../components/spotlight/Spotlight";
import { useEquipmentAggregationStatistics } from "../../hooks/UseEquipmentAggregationTimeperiods";
import { EventKeys } from "../../models/EventKeys";
import { timeFilterRenderType } from "../../models/EventFilter";
import { getEquipmentOverTimeProp } from "../../components/graph/EquipmentStatsLineGraph";

type TileModel = DashboadTileSettings & {
    kpiDefinition: KpiDefinition,
    endpoint: TimeperiodApis,
};

const initialFilters = [{
    filter: QuickFilterTypes.CurrentCalendarYear,
    minSeconds: 3 * 31 * 86400,
}, {
    filter: QuickFilterTypes.Rolling30Days,
    minSeconds: 20 * 24 * 60 * 60,
}, {
    filter: QuickFilterTypes.Rolling7Days,
    minSeconds: 5 * 24 * 60 * 60,
}, {
    filter: QuickFilterTypes.Rolling24Hours,
    minSeconds: 3 * 3600,
}];

const itemsWithSeparators = [QuickFilterTypes.CurrentWeek, QuickFilterTypes.CurrentMonth, QuickFilterTypes.CurrentCalendarYear];

const quickFilterList = [
    { label: "dashboard.quickFilter.currentDay", quickFilterType: QuickFilterTypes.CurrentDay },
    { label: "dashboard.quickFilter.lastDay", quickFilterType: QuickFilterTypes.LastDay },
    { label: "dashboard.quickFilter.rolling24Hours", quickFilterType: QuickFilterTypes.Rolling24Hours },
    { label: "dashboard.quickFilter.currentWeek", quickFilterType: QuickFilterTypes.CurrentWeek },
    { label: "dashboard.quickFilter.lastWeek", quickFilterType: QuickFilterTypes.LastWeek },
    { label: "dashboard.quickFilter.rolling7Days", quickFilterType: QuickFilterTypes.Rolling7Days },
    { label: "dashboard.quickFilter.currentMonth", quickFilterType: QuickFilterTypes.CurrentMonth },
    { label: "dashboard.quickFilter.lastMonth", quickFilterType: QuickFilterTypes.LastMonth },
    { label: "dashboard.quickFilter.rolling30Days", quickFilterType: QuickFilterTypes.Rolling30Days },
    { label: "dashboard.quickFilter.currentCalendarYear", quickFilterType: QuickFilterTypes.CurrentCalendarYear },
    { label: "dashboard.quickFilter.lastCalendarYear", quickFilterType: QuickFilterTypes.LastCalendarYear },
    { label: "dashboard.quickFilter.rollingYear", quickFilterType: QuickFilterTypes.RollingYear },
];

export function Dashboard() {
    const { projectId } = useParams<{
        projectId: string,
    }>();

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    session.setProject(projectId);

    const { trackEvent } = useMatomo();

    updateUserpilotUrl();

    const [selectedTileIdx, setSelectedTileIdx] = useState<number | undefined>(undefined);
    const [tileEditIdx, setTileEditIdx] = useState<number | undefined>(undefined);
    const [showShareModal, setShowShareModal] = useState(false);

    const addFavoritesModalRef = useRef<IModal>(null);

    // Perform grid layout
    const tileCount = 6;
    const containerRef = useRef<HTMLDivElement>(null);
    const tileSize = useGridLayout(containerRef, tileCount, {
        // When changing, remember to check that dashboard texts for main languages do not break on any screen sizes
        // Typical screen sizes should still see 3 tile columns
        minWidth: 350,
        minHeight: 250,
        desiredHeight: 300,
        xGap: 8  // needs to be about the same as the gap in the css (see gap value in dashboardContainer entry in Dashboard.scss)
    });
    const tileLayout = toGridLayout(tileSize);

    const [allStats, isAllStatsLoading] = useStatistics();

    const { hasRoutings, hasPlanning, showPlanningData } = getDashboardPlanningState(session, settings);

    const quickFilterSelected = quickFilter.get(settings.dashboard?.filter ?? QuickFilterTypes.CurrentWeek);

    // The dashboard state is primarily stored in the settings context.
    // However, shared views may overwrite these!
    useEffect(() => {
        if (session.project === undefined)
            return;

        fetchDashboardSettings(session, settings, projectId);
    }, [
        session.project,
        JSON.stringify(settings.dashboard),
    ]);

    // We use an empty filter list here because we are refering to the stats of the whole dataset.
    const [stats, isStatsLoading] = useStatistics(undefined, {
        onData: (data) => {
            // Only initialize quick filter if previously unset
            if (settings.dashboard?.filter !== undefined)
                return;

            const durationSeconds = ((data.maxDate?.getTime() ?? 0) - (data.minDate?.getTime() ?? 0)) / 1000;
            if (durationSeconds === 0)
                return;

            const initialFilter = initialFilters.find(f => durationSeconds > f.minSeconds)?.filter ?? QuickFilterTypes.Rolling7Days;

            queueMicrotask(() => {
                settings.mergeSet({
                    dashboard: {
                        filter: initialFilter,
                    },
                });
            });
        }
    });

    // We should keep in the dashboard filter list only the filters with end date less 
    // than the logs start date or filter time start date
    const validQuickFilters = useMemo(() => {
        const startDate = stats.minDate;

        return quickFilterList.filter((f) => {
            const timeRange = quickFilter.get(f.quickFilterType)?.timeRange(stats.maxDate);

            if (!timeRange?.end || !startDate)
                return false;

            return startDate <= timeRange?.end;
        });

    }, [stats.minDate, stats.maxDate]);

    const endDate = useMemo(() => {
        const filters = settings.previewFilters ?? settings.filters;
        const range = getFilterStartEndTimes(filters);

        if (range.maxTime !== undefined) {
            // When we are looking at times that are set via the filter editor, we add an offset of a second.
            // For filters that were added via action buttons this has an effect since they usually go until 23:59:59.999.
            // When we add an offset of a second this is rounded up to next day.
            // This especially makes sense for quick filters of the "last" type which rely on the endDate being on the next day and
            // also works for all of the other filters.
            // This makes sure that the views stay consistent when jumping back and forth.
            const maxTime = range.maxTime + 1;
            return new Date(Math.min(maxTime * 1000, stats.maxDate?.getTime() ?? 0));
        }

        return stats.maxDate;
    }, [stats.maxDate, settings.filters, settings.previewFilters]);

    // Changing the time range in the filter should update the end date used to display the timeperiods in the dashboard
    // and also update the dashboard filter if the selected dashboard filter is not included in the list of filters
    useEffect(() => {
        const isFilterValid = settings.dashboard?.filter ? validQuickFilters.some(f => f.quickFilterType === settings.dashboard?.filter) : undefined;

        settings.mergeSet({
            dashboard: {
                ...(isFilterValid === false ? { filter: validQuickFilters[0]?.quickFilterType } : {}),
            },
        });
    }, [
        validQuickFilters,
        settings.filters,
        settings.previewFilters,
        stats.maxDate,
    ]);

    // make stable reference so we can use this as a dependency in useMemo or useEffect.
    // also, annotate which tile is requested using which API
    const tileDefs = useMemo(() => {
        return (settings.dashboard?.tiles ?? getDefaultTiles(session, settings) ?? []).map(t => {
            if (t.kpiType === KpiTypes.CarbonPerOutput)
                return {
                    ...t,
                    kpiType: KpiTypes.Carbon,
                };
            if (t.kpiType === KpiTypes.EnergyPerOutput)
                return {
                    ...t,
                    kpiType: KpiTypes.Energy,
                };
            return t;
        }).map(t => {
            return {
                ...t,
                endpoint: decideEndpoint(session, settings, t) ?? TimeperiodApis.Case,
                kpiDefinition: t.kpiType !== undefined ? getKpiDefinition(t.kpiType, getContextFromTile(t, session, settings)) : undefined,
            };
        }) as TileModel[];
    }, [
        session,
        showPlanningData,
        JSON.stringify(settings.dashboard?.tiles)
    ]);

    // Request planning helper
    // Dictionary: Endpoint, WIP => tile
    function getKey(tileId: string, endpoint: TimeperiodApis) {
        return `${tileId}-${endpoint}`;
    }

    // Construct an empty request plan. It will hold a list of tiles that are mapped to a unique key.
    // Each unique key will correspond to exactly one request.
    const requestPlan: {
        [key: string]: {
            tiles: TileModel[],
            data: AggregatedTimeperiodSchema | undefined,
            isLoading: boolean,
            hash?: string,
        }
    } = {};

    // Add tiles to the request plan
    for (let iTile = 0; iTile < tileCount; iTile++) {
        const tileDef = tileDefs.at(iTile);
        const key = getKey(iTile.toString(), tileDef?.endpoint ?? TimeperiodApis.Case);
        if (!requestPlan[key])
            requestPlan[key] = {
                tiles: [],
                data: undefined,
                isLoading: true,
            };

        if (tileDef !== undefined)
            requestPlan[key]!.tiles.push(tileDef);
    }

    // Actually execute batched requests
    const isInitializing = session.project === undefined || quickFilterSelected === undefined || stats === undefined;

    let isSomeTileLoading = false;

    // Timeperiods displayed in the dashboard should be in the selected quick filter time range.
    // The request, however, may include lots of data outside of this range.
    const timeRange = getQuickFilterRange(settings, quickFilterSelected, endDate);
    const filterFunc = (t: AggregatedTimeperiodElementSchema) => {
        if (timeRange?.start !== undefined || timeRange?.end !== undefined) {
            return isInRange(
                toUserTimezoneMillis(timeRange.start.getTime(), session.timezone),
                toUserTimezoneMillis(timeRange.end.getTime(), session.timezone),
                toUserTimezone(t.timeperiodStartTime, session.timezone)
            );
        }
    };

    // Helper function that takes care of adding data to the request plan
    const appendData = (key: string, arr: [AggregatedTimeperiodSchema | undefined, boolean, string | undefined]) => {

        const elem = requestPlan[key];
        if (!elem)
            return;

        elem.data = !arr[0] ? undefined : {
            ...arr[0],
            timeperiods: arr[0].timeperiods?.filter(filterFunc),
        };
        elem.isLoading = arr[1] || isInitializing;
        elem.hash = arr[2];
    };

    for (let iTile = 0; iTile < tileCount; iTile++) {
        for (const endpoint of [TimeperiodApis.Case, TimeperiodApis.CaseDeviation, TimeperiodApis.Event, TimeperiodApis.Equipment]) {
            const key = getKey(iTile.toString(), endpoint);
            const tiles = requestPlan[key]?.tiles || [];

            const tile = tiles.at(0);
            const tileEndpoint = tile?.endpoint;
            const keyComponents = {
                isWipIncluded: !!tile?.kpiDefinition?.isWipIncluded,
                equipment: tile?.workplace,
                kpiAggregation: tile?.kpiAggregation,
            };
            const requestOptions = {
                frequency: quickFilterSelected?.frequency,
                sort: ["-timeperiodStartTime"],
                limit: 1000,
                ...disableAllCalcOptions,
                customKpis: getCustomKpis(tiles),
                ...getCalculateOptions(tiles),
                eventFilters: getFiltersForKpi(tile?.kpiDefinition, settings, session),
            };

            const hookOptions = { disable: isInitializing || !tiles?.length || endpoint !== tileEndpoint, addEnergyStats: true, hash: true, };
            const calculatePlanned = showPlanningData && hasRoutings;

            switch (endpoint) {
                case TimeperiodApis.Case: {
                    appendData(key, useTimeAggregatedCaseStatistics({ ...requestOptions, calculatePlanned }, hookOptions));
                    break;
                }
                case TimeperiodApis.CaseDeviation: {
                    appendData(key, useDeviationTimeperiodStatistics(requestOptions, hookOptions));
                    break;
                }
                case TimeperiodApis.Event: {
                    appendData(key, useTimeAggregatedEventStatistics({ ...requestOptions, calculatePlanned }, hookOptions));
                    break;
                }
                case TimeperiodApis.Equipment: {
                    appendData(key, useEquipmentAggregationStatistics({
                        ...requestOptions,
                        eventKeys: { ...session.project?.eventKeys ?? {}, activityKeysGroup: keyComponents.kpiAggregation ?? GroupingKeys.Machine } as EventKeys,
                        calculatePlanned,
                        equipment: keyComponents.equipment ? [keyComponents.equipment] : undefined,
                    }, hookOptions));
                    break;
                }
            }
            isSomeTileLoading = isSomeTileLoading || requestPlan[key]?.isLoading;

        }
    }

    const dataHash = getHash(flatMap(Object.keys(requestPlan), k => requestPlan[k].hash ?? []).sort());
    const loadingHash = getHash(flatMap(Object.keys(requestPlan), k => requestPlan[k].isLoading ? k : []).sort());

    // Get the last x value
    const lastXValue = Object.keys(requestPlan).map(k => {
        const t = requestPlan[k].data?.timeperiods?.[0]?.timeperiodStartTime;
        if (!t)
            return undefined;
        return toUserTimezone(t, session.timezone);
    }).filter(t => t !== undefined).sort((a, b) => {
        return -timestampSort(a, b);
    })[0];

    const newTimeFilter = buildTimeFilter(timeRange?.start, timeRange?.end, false, false, false);
    const newFilters = newTimeFilter ? settings.filters.filter(f => f.renderType !== timeFilterRenderType).concat(newTimeFilter) : settings.filters;

    // Render tiles
    const { dashboardTiles } = useMemo(() => {
        const dashboardTiles: JSX.Element[] = [];

        for (let idx = 0; idx < tileDefs.length; idx++) {
            const tile = tileDefs[idx];

            if (!tile.kpiDefinition) {
                dashboardTiles.push(getEmptyTile(idx));
                continue;
            }

            const key = getKey(idx.toString(), tile.endpoint);

            const data = requestPlan[key]?.data;
            const isLoading = requestPlan[key]?.isLoading ?? true;
            if (isLoading) {
                dashboardTiles.push(getLoadingTile(settings, tile, idx));
                continue;
            }

            const unit = getUnit(tile.kpiDefinition.unit, tile.statistic) ?? tile.kpiDefinition.unit as UnitMetadata;
            const scale = unit.name === "percent" ? 100 : 1;
            const localPath = getStatisticsPath(tile);

            const times = data?.timeperiods.map(t => new Date(t.timeperiodStartTime).getTime()) ?? [];

            const values = getTimeseries(data?.timeperiods, `actual.${localPath}`, scale, times);
            const planValues = !showPlanningData || !tile.kpiDefinition.allowedComparisons.includes(KpiComparisons.Planning) || tile.workplace !== undefined ? [] :
                getTimeseries(data?.timeperiods, `planned.${localPath}`, scale, times);

            const value = values[0]?.y;
            const prevValue = values[1]?.y;
            const planValue = planValues[0]?.y;

            const allValuesUndefined = values.every(value => value.y === undefined);

            if (allValuesUndefined) {
                dashboardTiles.push(getEmptyTile(idx, tile.kpiDefinition, tile.statistic, tile.workplace, tile.endpoint));
                continue;
            }

            dashboardTiles.push(<DashboardTile
                key={`tile-${tile?.kpiType ?? ""}-${tile?.quantity ?? ""}-${tile?.statistic}-${idx}`}
                spotlight={tile.workplace === undefined ? `${tile.kpiDefinition.spotlightId}-Dashboard-${capitalize(tile.statistic)}` : tile.kpiDefinition.spotlightId}
                statistic={tile.statistic}
                values={values}
                planValues={planValues}
                kpiType={tile.kpiType}
                workplace={tile.workplace}
                apiType={tile.endpoint}
                grouping={tile.kpiAggregation}
                value={value}
                planValue={planValue}
                prevValue={prevValue}
                isLoading={isLoading}
                unit={unit}
                actionButtonFilters={newFilters}
                scales={getLongUnit(unit).getUnits({
                    baseQuantity: tile.quantity,
                })}
                isLessBetter={tile.kpiDefinition.isLessBetter}
                title={i18n.t(tile.kpiDefinition.label).toString()}
                xTickFrequency={quickFilterSelected?.frequency}
                onSettingsClick={() => {
                    setTileEditIdx(idx);
                }}
                isSelected={Global.isTouchEnabled && idx === selectedTileIdx}
                onClick={() => {
                    setSelectedTileIdx(selectedTileIdx === idx ? undefined : idx);
                }}
            />);
        }

        return { dashboardTiles };
    }, [
        tileDefs,
        quickFilterSelected,
        endDate,
        showPlanningData,
        dataHash,
        loadingHash,
        selectedTileIdx,
    ]);

    // Generate view
    const isNoDataAvailable = !isAllStatsLoading && allStats.numFilteredTraces === 0;
    const isLoading = !isNoDataAvailable && (isSomeTileLoading || isStatsLoading || !lastXValue);

    const [showProjectLoadingSpinner, setShowProjectLoadingSpinner] = useState(!Global.projectLoadingSpinnerHasBeenShown);
    useEffect(() => {
        if (isLoading || !lastXValue || !session.project)
            return;

        setShowProjectLoadingSpinner(false);
    }, [
        isLoading
    ]);
    const showProjectLoadingSpinnerInsteadOfDashboard = !session.projectId || showProjectLoadingSpinner && isLoading;

    const setFrequency = (f: QuickFilterTypes) => settings.mergeSet({
        dashboard: {
            filter: f,
        },
    });

    return <div className={classNames(["dashboard", "dashboardFilter"])}>
        <Spinner isLoading={showProjectLoadingSpinnerInsteadOfDashboard} text="common.projectInitializing" />
        <div className={classNames(["dashboardContainer", settings.filterEditor.showFilterEditor && "filterExpanded", showProjectLoadingSpinnerInsteadOfDashboard && "hidden"])} ref={containerRef}>
            <div className="dashboardContent">
                <div className="pageHeader">
                    <div className="greeting">
                        {i18n.t("common.dashboard")}
                    </div>

                    {!isNoDataAvailable && <div className="buttons">
                        {/* Compare with plan */}
                        {hasPlanning && <div>
                            <div className="buttonesque"
                                onClick={() => settings.mergeSet({
                                    dashboard: {
                                        // invert current state
                                        showPlanningData: !showPlanningData,
                                    },
                                })}>
                                <ReactSwitch
                                    id="switch-planning-comparison"
                                    height={16}
                                    handleDiameter={12}
                                    width={27}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offColor={colors["$gray-2"]}
                                    onColor={colors["$primary-500"]}
                                    checked={showPlanningData}
                                    onChange={noop}
                                />
                                <label className="clickable">
                                    {i18n.t("common.planComparison")}
                                </label>
                            </div>
                        </div>}

                        {/* Quick Filter selection */}
                        <Menu className="menuLight" items={validQuickFilters.map(filter => ({
                            title: i18n.t(filter.label).toString(),
                            onClick: () => { setFrequency(filter.quickFilterType); },
                            separator: itemsWithSeparators.includes(filter.quickFilterType) ? {
                                placement: "above",
                                type: "line"
                            } : undefined
                        }))
                        }>
                            <div className="buttonesque" data-testid="frequency-selection">
                                <svg className="svg-icon xsmall brandHover"><use xlinkHref="#radix-calendar" /></svg>
                                <ValueSpinner isLoading={settings.dashboard?.filter === undefined}>
                                    <>
                                        {settings.dashboard?.filter !== undefined && i18n.t("dashboard.quickFilter." + settings.dashboard.filter?.toString())}
                                    </>
                                </ValueSpinner>
                            </div>
                        </Menu>

                        <Menu className="menuLight" items={[{
                            title: i18n.t("dashboard.resetDashboard").toString(),
                            onClick: async () => { await resetDashboardSettings(session, settings, projectId); }
                        }]}>
                            <div className="buttonesque">
                                <svg className="svg-icon xsmall brandHover"><use xlinkHref="#radix-gear" /></svg>
                            </div>
                        </Menu>

                        <div className="buttonesque" title={i18n.t("favorites.saveView").toString()} onClick={() => {
                            addFavoritesModalRef.current?.show();
                        }}>
                            <svg className="svg-icon xsmall clickable brandHover">
                                <use xlinkHref="#radix-star" />
                            </svg>
                        </div>
                        {session.project?.isSharedWithOrganization && <div className="buttonesque" title={i18n.t("common.shareView").toString()}
                            onClick={async () => {
                                const sharingWorked = await shareAsync(session, settings);

                                trackEvent({
                                    category: "Interactions",
                                    action: "Shared view",
                                    name: sharingWorked ? "navigator" : "fallback",
                                });

                                setShowShareModal(!sharingWorked);
                            }}>
                            <svg className="svg-icon xsmall clickable brandHover">
                                <use xlinkHref="#radix-share-1" />
                            </svg>
                        </div>}
                    </div>}
                </div>
                <div className="grid">
                    {(dashboardTiles?.length ?? 0) > 0 && !isNoDataAvailable && <div className="tilesContainer" style={{ ...tileLayout }}>
                        {dashboardTiles}
                    </div>}
                    <NoDataAvailable
                        message="dashboard.noDataAvailable"
                        visible={isNoDataAvailable}
                    />
                </div>
            </div>

            <FilterEditor />
        </div>

        <EditFavoritesModal ref={addFavoritesModalRef} />

        {showShareModal && <ShareModal onDone={async () => setShowShareModal(false)} />}

        {tileEditIdx !== undefined && <DashboardTileSettingsModal
            initialValue={tileDefs?.[tileEditIdx ?? 0]}
            onCancel={() => {
                setTileEditIdx(undefined);
            }}
            onChange={(kpi, statistic, quantity, aggregation, workplace) => {
                const newTileDefs = (tileDefs ?? []).map((t, idx) => {
                    if (idx !== tileEditIdx)
                        return t;

                    return {
                        kpiType: kpi,
                        statistic: statistic,
                        quantity: quantity,
                        kpiAggregation: aggregation,
                        workplace: workplace,
                    };
                });
                setTileEditIdx(undefined);
                settings.mergeSet({
                    dashboard: {
                        tiles: newTileDefs,
                    },
                });
            }}
        />}
    </div>;

    function getEmptyTile(idx: number, kpiDefinition?: KpiDefinition, statistic?: StatisticTypes, workplace?: string | undefined, apiType?: TimeperiodApis) {
        const subtitle = getSubtitle(apiType, workplace);

        return <div className="dashboardTile" key={`empty-tile-${idx}`}>
            <div className="header">
                <div className="title">
                    <div className="titleControlsContainer">
                        <span className="titleText oneRow">{i18n.t(kpiDefinition?.label ?? "").toString()}</span>
                        <div className="dashboardTileControls">
                            {kpiDefinition?.spotlightId && <Spotlight id={`${kpiDefinition.spotlightId}-Dashboard-${capitalize(statistic)}`} className=" " />}
                            <svg className="svg-icon xxsmall clickable brandHover edit" data-testid="tile-options" onClick={(e) => {
                                setTileEditIdx(idx);
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                                <use xlinkHref="#radix-pencil-1" />
                            </svg>
                        </div>
                    </div>
                </div>
                <span className="subtitleText">{subtitle}</span>
            </div>
            <NoDataAvailable
                title="dashboard.noTileDataAvailable"
                visible={!!kpiDefinition}
            />
        </div>;
    }

    function getStatisticsPath(tile: TileModel) {
        if (tile.workplace !== undefined && tile.kpiAggregation !== undefined)
            return getEquipmentOverTimeProp(tile.kpiDefinition, tile.statistic);
        return getTimeperiodStatisticPath(tile.kpiDefinition, tile.statistic) ?? getProductStatisticPath(tile.kpiDefinition, tile.statistic);
    }
}

function getSubtitle(apiType?: TimeperiodApis, workplace?: string): string {
    if (workplace) return workplace;
    if (apiType === TimeperiodApis.Equipment || apiType === TimeperiodApis.Event)
        return i18n.t("common.allMachines");
    return i18n.t("common.allCases");
}

/**
 * Loads dashboard settings from local storage
 * @returns DashboardSettingsType instance or undefined
 */
export function getDashboardSettings(session: SessionType, projectId: string | undefined) {
    const tileSettingsKey = `${session.user?.hash ?? "?"}/settings/${projectId ?? session.projectId}/dashboard-ng`;
    const json = localStorage.getItem(tileSettingsKey);
    if (json)
        try {
            return JSON.parse(json) as DashboardSettingsType;
        } catch (e) {
            // Ignore
        }
    return undefined;
}

/**
 * Serializes the dashboard settings into local storage
 */
function writeDashboardSettings(session: SessionType, settings: SettingsContextType, dashboard?: DashboardSettingsType | undefined, projectId?: string) {
    const tileSettingsKey = `${session.user?.hash ?? "?"}/settings/${projectId ?? session.projectId}/dashboard-ng`;
    const dashboardSettings = dashboard === undefined ? settings.dashboard : dashboard;
    const json = JSON.stringify({
        ...dashboardSettings,
        // Prevent internal state to leak into local storage
        tiles: !dashboardSettings?.tiles ? undefined :
            dashboardSettings?.tiles.map(t => {
                return {
                    kpiType: t.kpiType,
                    statistic: t.statistic,
                    quantity: t.quantity,
                    kpiAggregation: t.kpiAggregation,
                    workplace: t.workplace,
                };
            }),
    });
    localStorage.setItem(tileSettingsKey, json);
}

/**
 * Decides for a given tile, if the deviation or case endpoint should be used.
 * Not sure but this function could probably be collapsed to
 * return tile.kpiDefinition.timeperiodApi ?? TimeperiodApis.Case;
 * @returns Endpoint type or undefined if the tile cannot be displayed
 */
function decideEndpoint(session: SessionType, settings: SettingsType, tile: DashboadTileSettings) {
    const { showPlanningData } = getDashboardPlanningState(session, settings);
    const context = getContextFromTile(tile, session, settings);
    const kpiDef = getKpiDefinition(tile.kpiType, context);
    const isEquipmentStats = tile.kpiAggregation !== undefined && tile.workplace !== undefined;
    return decideTimeperiodApi(context.session, kpiDef, showPlanningData, tile.quantity, isEquipmentStats);
}


export function getDefaultTiles(session: SessionType, settings: SettingsType) {
    const { hasPlanningLog } = getDashboardPlanningState(session, settings);
    const result = [
        { type: KpiTypes.ThroughputTime, statistic: StatisticTypes.Mean },
        { type: KpiTypes.ProductionProcessRatio, statistic: StatisticTypes.Mean },
        { type: KpiTypes.QueuingTime, statistic: StatisticTypes.Mean },
        { type: KpiTypes.ThroughputRate, statistic: StatisticTypes.Mean },
        { type: KpiTypes.ScrapRatio, statistic: StatisticTypes.Mean },
        { type: KpiTypes.Carbon, statistic: StatisticTypes.Mean },
        { type: KpiTypes.GoodQuantity, statistic: StatisticTypes.Sum },
        { type: KpiTypes.DeviationThroughputTime, statistic: StatisticTypes.Mean },
        { type: KpiTypes.OnTimeDelivery, statistic: StatisticTypes.Mean },
        { type: KpiTypes.OrderCount, statistic: StatisticTypes.Sum },
    ].map(kpi => {
        const kpiDef = getKpiDefinition(kpi.type, getContextOverride(session, settings, { statistic: kpi.statistic }));
        if (!kpiDef)
            return undefined;

        const isQuantityMissing = kpiDef.isQuantityDependent && kpiDef.allowedQuantities.actual.case[0] === undefined;
        const isPlanMissing = kpiDef.requiresPlanningData && !hasPlanningLog;
        const isRequiredEventKeysMissing = kpiDef.requiredEventKeys && kpiDef.requiredEventKeys.some(k => get(session.project, k) === undefined);

        if (!kpiDef || isQuantityMissing || isPlanMissing || isRequiredEventKeysMissing)
            return undefined;

        return {
            kpiType: kpi.type,
            statistic: kpi.statistic,
            quantity: kpiDef.isQuantityDependent ? kpiDef.allowedQuantities.actual.case[0] : undefined,
        } as DashboadTileSettings;
    }).filter(d => d !== undefined).slice(0, 6) as DashboadTileSettings[];

    return result;
}

/**
 * Gets the custom KPIs that are used in the tiles provided
 */
function getCustomKpis(tileDefs: TileModel[] | undefined) {
    return uniqBy(flatMap((tileDefs ?? []).map(def => {
        if (def.endpoint === TimeperiodApis.Event)
            return def.kpiDefinition?.eventOverTimeCustomKpis ?? [];
        if (def.endpoint === TimeperiodApis.Equipment)
            return def.kpiDefinition?.equipmentNodeCustomKpis ?? [];

        return def.kpiDefinition?.productCustomKpis ?? [];
    })).filter(e => e !== undefined), e => e!.id) as CustomKpi[];
}

/**
 * Get custom kpi definitions for over time statistics
 */
export function getTimeCustomKpis(endpoint: TimeperiodApis | undefined, kpiDefinition: KpiDefinition | undefined) {
    if (endpoint === TimeperiodApis.Event)
        return kpiDefinition?.eventOverTimeCustomKpis ?? [];
    if (endpoint === TimeperiodApis.Equipment)
        return kpiDefinition?.equipmentNodeCustomKpis ?? [];

    return kpiDefinition?.productCustomKpis ?? [];
}

/**
 * Returns a StatsCalculationRequest instance where all properties are true that are used
 * in the tiles provided
 */
function getCalculateOptions(tileDefs: TileModel[] | undefined) {
    const result: { [key: string]: boolean } = {};

    for (const tile of tileDefs ?? []) {
        Object.keys(tile.kpiDefinition?.apiParameters ?? {}).forEach(key => {
            const prop = key as keyof StatsCalculationRequest;
            if (isBoolean(tile.kpiDefinition.apiParameters![prop]) &&
                tile.kpiDefinition.apiParameters![prop] === true)
                result[key] = true;
        });
    }

    return result as StatsCalculationRequest;
}

function getLoadingTile(settings: SettingsType, tile: DashboadTileSettings, idx: number) {
    const key = `tile-${tile.kpiType ?? ""}-${tile.quantity ?? ""}-${tile.statistic}-${idx}`;
    return <DashboardTile
        key={key}
        values={[]}
        planValues={[]}
        value={0}
        kpiType={tile.kpiType}
        workplace={tile.workplace}
        grouping={tile.kpiAggregation}
        planValue={0}
        prevValue={0}
        isLoading={true}
        unit={Formatter.defaultUnit}
        scales={Formatter.defaultUnit.getUnits({})}
        isLessBetter={true}
        title={""}
        xTickFrequency={quickFilter.get(settings.dashboard?.filter ?? QuickFilterTypes.CurrentWeek)?.frequency}
    />;
}

function getTimeseries(data: AggregatedTimeperiodElementSchema[] | undefined, path: string | undefined, scale: number, times: number[]) {
    if (!path || !data)
        return [];

    return data.map((t, idx) => {
        const value = get(t, path ?? "");

        return {
            y: isNiceNumber(value) ? value * scale : undefined,
            x: times[idx]
        };
    }).filter(a => a !== undefined) as Point[] ?? [];
}


function getDashboardPlanningState(session: SessionType, settings: SettingsType) {
    const planningState = getPlanningState(session);
    // default to showing planning data if it's available and use the setting if it is set.
    const showPlanningData = planningState.hasPlanning && (settings.dashboard?.showPlanningData || settings.dashboard?.showPlanningData === undefined);
    return {
        ...planningState,
        showPlanningData
    };
}

function getQuickFilterRange(settings: SettingsContextType, quickFilterSelected: QuickFilterDefinition | undefined, maxDate: Date | undefined) {
    const timeRange = quickFilterSelected?.timeRange(maxDate);
    return timeRange;
}

async function fetchDashboardSettings(session: SessionType, settings: SettingsContextType, projectId: string | undefined) {
    if (projectId === undefined)
        return;

    // First render if settings and local storage don't have dashboard data, then display the default dashboard
    if (settings.dashboard?.tiles === undefined) {
        try {
            const viewConfiguration = await Api.getViewConfigurations({
                projectIdEq: projectId,
                viewTypeEq: ViewConfigurationType.Dashboard
            });

            if (viewConfiguration.length > 0) {
                settings.mergeSet({
                    dashboard: viewConfiguration[0].settings.dashboard,
                });
                writeDashboardSettings(session, settings, viewConfiguration[0].settings.dashboard);
                return;
            }

        } catch {
            // ignore
        }
    }

    // If we don't have a default dashboard and we don't have dashboard data in settings, then get it from local storage
    if (settings.dashboard === undefined) {
        const dashboardSettings = getDashboardSettings(session, projectId);
        if (dashboardSettings)
            settings.mergeSet({
                dashboard: {
                    ...dashboardSettings,
                },
            });
        return;
    }

    // Update local storage tile settings
    if (settings.dashboard.tiles)
        writeDashboardSettings(session, settings);
}

async function resetDashboardSettings(session: SessionType, settings: SettingsContextType, projectId: string | undefined) {
    if (session.project === undefined || projectId === undefined)
        return;

    try {
        const viewConfiguration = await Api.getViewConfigurations({
            projectIdEq: projectId,
            viewTypeEq: ViewConfigurationType.Dashboard
        });
        if (viewConfiguration.length > 0) {
            settings.mergeSet({
                dashboard: viewConfiguration[0].settings.dashboard,
            });
            writeDashboardSettings(session, settings, viewConfiguration[0].settings.dashboard);
            return;
        }
    } catch {
        // ignore
    }

    settings.mergeSet({
        dashboard: {
            ...settings.dashboard,
            tiles: getDefaultTiles(session, settings),
        }
    });
}
