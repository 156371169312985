import React from "react";

export type AvatarProps = {
    name?: string;
    shadow?: boolean;
};

export function Avatar(props: AvatarProps) {
    return <div className="avatar">
        <div className="front" title={props.name}>
            {!!props.name?.length && props.name[0]}
        </div>
        {props.shadow && <>
            <div className="shadow shadow-1"></div>
            <div className="shadow shadow-2"></div>
        </>}
    </div>;
}
